import { headingBlue, pureWhite, red } from "../assets/colors";

export var containerStyle = {
	margin: "auto",
	color: headingBlue,
	fontSize: "1.1rem",
	maxWidth: "25rem",
	overflow: "auto",
	padding: "0.5rem 1.5rem 0.5rem 1.5rem",
	// marginTop: "0.5rem",
};

export var imgContainer = {
	textAlign: "center",
	padding: "0.8rem",
};

export var imgStyle = {
	width: "40%",
};
export var paperStyle = {
	color: headingBlue,
	padding: "0.5rem 0rem 0rem 0.2rem",
};
export var typographyStyle = {
	fontSize: "1rem",
	textAlign: "left",
	padding: "0rem 0rem 0rem 1rem",
};

export var customTextFieldStyle = {
	// width: "70%",s
	// marginTop: "0.5rem",
	// marginBottom: "0.5rem",
	padding: "1rem 0rem 0.5rem 0rem",
};
export var buttonContainer = {
	textAlign: "center",
};
export var buttonStyle = {
	backgroundColor: headingBlue,
	padding: "0.5rem 0.6rem 0.5rem 0.6rem",
	color: pureWhite,
	border: "2px solid ",
	borderRadius: "0.4rem",
	fontSize: "1rem",
	marginTop: "0.5rem",
	marginBottom: "0.8rem",
	onfocus: "",
};

export var errorStyleText = {
	color: red,
	padding: "0.2rem 0rem 0rem 0rem",
	// marginTop: 1,
	fontSize: 11,
	// marginLeft: 5
};

export var boxStyle = {
	display: "flex",
	flex: "1 1",
	flexWrap: "wrap",
	flexDirection: "column",
	textAlign: "center",
	justifyContent: "center",
	padding: "0.2rem 0.2rem 1rem 0.2rem",
};

export var ButtonStyle = {
	backgroundColor: headingBlue,
	color: "white",
	// marginRight: '0.2rem',
	marginTop: "0.4rem",
	// marginLeft: "0.7rem",
	fontFamily: "Helvetica",
	fontWeight: "300",
	fontSize: "0.9rem",
	padding: "0.2rem 0rem 0.2rem 0rem",
};
