import { headingBlue, mediumGrey } from "../assets/colors"

export var containerStyle= {
    // width: '100%',
    padding: "0.5rem 1.5rem 1.5rem 1.5rem"
  }




  // Table CSS

  export var outerBoxStyle= {
    width: '100%',
    height: '50vh',
    marginTop: "0.6rem"
  }

  export var paperStyle={
    // width: "100%",
    // height: '100%',
    border: '1px solid #D9D9D9',
    padding: "0.2rem 0.1rem 0.1rem 0.1rem",
  }

  export var typographyStyle= {
    marginLeft: "0.5rem",
    padding:"0.1rem",
    fontSize: "1rem",
    fontWeight: "600"
  }

  export var headerBoxStyle = {
    display: 'flex',
    padding: "0.3rem",
    flexWrap: 'wrap',
  }

  export var internalBoxStyle= {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '20%',
    flex: '1 1',
    flexDirection: "row",
    flexWrap: 'wrap'
    // padding: "0.1rem 2rem"
  }

  export var buttonStyle={
    backgroundColor: headingBlue,
    color: 'white',
    // marginRight: '0.2rem',
    marginLeft: "0.7rem",
    fontFamily: "Helvetica",
    fontWeight: '300',
    fontSize: '0.9rem',
    padding: "0.2rem 1rem 0.2rem 1rem"
  }

  export var tableHeaderStyle={
    backgroundColor: mediumGrey,
  }

  export var tableHeaderBoxStyle={
    display: 'flex', 
    justifyContent: 'center', 
    alignItems :'center', 
    padding :'0rem',
}

export var tableIconBoxStyle= {
    display: 'flex', 
    alignItems: 'center', 
    flexDirection: 'column' 
}

  export var headerRowStyle= {
    // display: 'flex', 
    // justifyContent: 'space-between'
  }

  export var headerCellStyle = {
    padding: '0.2rem 0.5rem 0.2rem 0.5rem', 
    fontSize: "0.9rem", 
    fontWeight: "400",
    textAlign: 'center'
  }

  export var iconBoxStyle = { 
    display: 'flex', 
    alignItems: 'center', 
    flexDirection: 'column', 
    padding: "0rem",
  }

  export var iconStyle = { 
    padding: "0rem 0rem 0rem 0.2rem",
    // marginBottom: '-0.3rem',
    // border: '1px solid'
  }

  export var paginationOuterBox = {
    display: 'flex', 
    justifyContent: 'center',
    padding: "0.4rem 0rem 0.1rem 0rem"
}

export var paginationInnerBox = {
    width: '50%',
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center'
}