import { Container } from "@mui/material";
import React from "react";
import { headingBlue } from "../assets/colors";
import { monthlyOnlineTasks } from "../data/taskCollections";
import CustomCheckbox from "./CustomCheckbox";

const MonthlyOnlineTasks = ({
	checkboxStates,
	setCheckboxStates,
	isButtonSubmit,
}) => {
	const handleCheckboxChange = (taskName) => {
		setCheckboxStates((prevState) => ({
			...prevState,
			[taskName]: !prevState[taskName],
		}));
	};
	return (
		<Container
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				gap: "0.2rem",
				padding: "1rem 0.4rem 1rem 0.4rem",
			}}
		>
			<p
				style={{
					margin: "auto",
					fontSize: "1.2rem",
					fontWeight: "bold",
					color: headingBlue,
				}}
			>
				Online Tasks
			</p>
			{monthlyOnlineTasks.map((task) => (
				<CustomCheckbox
					taskId={task.taskId}
					key={task.taskId}
					label={task.taskTitle}
					description={task.taskDescription}
					isCheckboxChecked={checkboxStates[task.taskId]}
					setIsCheckboxChecked={() => handleCheckboxChange(task.taskName)}
					isButtonSubmit={isButtonSubmit}
				/>
			))}
		</Container>
	);
};

export default MonthlyOnlineTasks;
