import React from 'react'
import { Box, Paper, Button, Typography, ButtonGroupContext, Grid, Container, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, useTheme, useMediaQuery } from '@mui/material';
import { headingBlue, textColor } from '../assets/colors';
import { useNavigate } from 'react-router-dom';
import { validateEmail, validatePassword } from '../utils/validations';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CustomTextField from './CustomTextField';
import Loader from './Loader';
import { login } from '../backend/backendApis';
import HttpService from '../backend/requestApis';


const LoginScreen = () => {

  const navigate = useNavigate()
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.up('sm'))

  const [userName, setUserName] = React.useState(null)
  const [password, setPassword] = React.useState(null)
  const [userNameError, setUserNameError] = React.useState(null)
  const [passwordError, setPasswordError] = React.useState(null)
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoader, setIsLoader] = React.useState(true)
  const [snackbarOpen, setSnackbarOpen] = React.useState(true)
  const [snackBarText, setText] = React.useState("");
  const [snackbarSeverity, setSeverity] = React.useState("Info");

  const handleClickShowPassword = () => 
    setShowPassword(() => !showPassword);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const handleLogin = () => {
    try{
      let emailValidateResult = validateEmail(userName)
      let passwordValidationResult = validatePassword(password)

      if( emailValidateResult === "" && passwordValidationResult === ""){
        let options= {
          url: login,
          method: "POST",
          payload:{
            email: userName,
            password: password
          }
        }
        setIsLoader(true)
        HttpService(options).then(async (data) => {
          await localStorage.setItem('token', data.data.data.token)
          await localStorage.setItem('designation',data.data.data.designation)
          setText("")
          setSnackbarOpen(false)
          setIsLoader(false)
          navigate('/dashboard')
        }).catch(err => {
          console.log("Error in login: ", err)
          setIsLoader(false)
          setText(err?.response?.data?.message ? err?.response?.data?.message : "Error in Login, Contact Support.")
          setSeverity('Failure')
          setSnackbarOpen(true)
        })
      } else {
        setIsLoader(false)
        setUserNameError(emailValidateResult)
        setPasswordError(passwordValidationResult)
      }
    } catch(err){
      console.log("Error in login: ", err)
      setIsLoader(false)
    }
  }

  const handleClickEvent = (msg, severity) => {
    setSnackbarOpen(true);
    setText(msg)
    setSeverity(severity)
  };

  const handleToClose = (event, reason) => {
    if ("clickaway" == reason) 
      return;
    setSnackbarOpen(false);
    setText("")
    };

  React.useEffect(() => {
    async function getToken(){
      try{
        setSnackbarOpen(false)
        setText('')
        const token = await localStorage.getItem('token')
        if( token){
          setIsLoader(false)
          navigate('/dashboard')
          } else {
            setIsLoader(false)
          }
      } catch(err){
        setIsLoader(false)
        console.log('err in fetching token: ', err)
      }
    }
    getToken()
  }, [])

  var innerBox1 = {
    // height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: headingBlue,
    padding: "0rem"
  }
  var innerBox2 = {
    // height: '100vh',
    display: 'flex',
    justifyContent: isSm ? "center" : 'flex-start',
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    flexDirection: 'column',
    // padding: "1rem 0rem 1rem 0rem"
  }

  var imageBox = {
    padding: "0.1rem 0.5rem 0.5rem 0.5rem",
    // backgroundColor: "#FFFFFF",
    // height: "14rem",
    // width: "14rem",
    // borderRadius: "50%",
    // borderRadius: "10%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: '98%'
  }

  var headingStyle={
    color: headingBlue,
    fontSize: "1.7rem",
    fontFamily: "Helevetica",
    textAlign: "center",
    fontWeight: 600

  }

  var paperStyle={
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    padding: "0.4rem 3rem 1rem 3rem",
    marginTop: "1rem",
  }

  var errorTextStyle = {
    color: "red",
    fontSize: "0.7rem",
    marginLeft: "0.4rem",
    padding: "0.1rem",
    fontWeight: 500
  }

  var buttonStyle = {
    backgroundColor: headingBlue,
    color: "#FFFFFF",
    width: "2rem"
  }

  return (
    <>
    {isLoader ? 
      <Loader></Loader>
      :
      <Grid container spacing={0} style={{height: "100vh"}}>
        <Grid item xs={12} sm={6} style={innerBox1} sx={{height: {xs: '0%', sm: '100%'}}}>
          {isSm && 
          <>
            <Box style={{...imageBox}}>
              <img src={require("../assets/images/WORK_logo.png")} style={{width: "auto", height: "7rem"}}></img>
            </Box>
          </>
          }
        </Grid>
        <Grid item xs={12} sm={6} style={innerBox2}>
          {!isSm && 
            <>
              <Box style={{padding: "0.5rem 0.5rem 1rem 0.5rem"}}>
                <img src={require("../assets/images/WORK_logo.png")} style={{width: "auto", height: "5rem"}}></img>
              </Box>
            </>
          }
          <Typography style={headingStyle}> New Districts Management System</Typography>
          <Paper style={paperStyle}>
            <Box style={{padding: "0.3rem"}}>
              <Typography style={{...headingStyle, fontSize: "1.75rem", fontWeight: "600"}}> Login</Typography>
            </Box>
            <Box style={{padding: "0.3rem", marginTop: "0.2rem"}}>
              <CustomTextField placeHolder="Username" value={userName}  setFunc={setUserName} regular={true}></CustomTextField>
              {userNameError ? 
              <Typography style={errorTextStyle}> {userNameError}</Typography>
              : <></>}
            </Box>
            <Box style={{padding: "0.3rem"}}>
            <FormControl 
              sx={{width: '14rem'}} 
              variant="outlined"
            >
              {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                placeholder='Password'
                sx={{
                  height: '2rem', // Set the desired height here
                  // width: '13.4rem', // Optionally override width here if needed
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility style={{color:headingBlue}} /> : <VisibilityOff /> }
                    </IconButton>
                  </InputAdornment>
                }

                onChange={handlePasswordChange}
                
              />
            </FormControl>
            { passwordError ? 
                <Typography style={errorTextStyle}> {passwordError}</Typography>
                : <></>
            }
            </Box>
            {snackbarOpen ? (
              <Typography style={{...errorTextStyle, fonstSize: '1rem', textAlign: 'center', paddingTop: '0.3rem', fontWeight: 550}}>{snackBarText}</Typography>
              ) : ""}
            <Box style={{display: "flex", justifyContent: 'center', margin: "0.75rem"}}>
            <Button style={buttonStyle} onClick={() => {
              handleLogin()
            }}>Login</Button>
            </Box>

          </Paper>
          
          
        </Grid>
      </Grid>
    }
      
    </>
  )
}

export default LoginScreen