import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Typography,
} from "@mui/material";
import React from "react";
import {
	headingBlue,
	maroon,
	red,
	textColor,
	yellowish,
} from "../assets/colors";
import { deleteMemberAccount, makeOrRemoveLeader } from "../backend/backendApis";
import HttpService from "../backend/requestApis";
import { useNavigate } from "react-router-dom";

const ConfirmationDialogBox = (props) => {
	const navigate = useNavigate();
	const [confirmError, setConfirmError] = React.useState("");
	console.log(props);

	const handleConfirm = () => {
		try {
			console.log("props.operation: ", props.operation)
			if( props.operation === 'delete'){
				let options = {
					url: deleteMemberAccount,
					method: 'DELETE',
					payload:{
						contact: props.contactNumber
					},
					headers: {
						Authorization: localStorage.getItem("token")
							? `Bearer ${localStorage.getItem("token")}`
							: "",
					},
				}
				HttpService(options)
					.then(() => {
						props.updateLeaderDialogStatus(false, "", null, null, false, true);
						// props.setRefreshAllianceMembersDetails(true);
					})
					.catch((err) => {
						console.log("Error in handling confirm: ", err);
						if (err?.status === 403) {
							localStorage.clear();
							navigate("/");
						} else {
							if( err?.status === 404){
								setConfirmError("No user Account Found With this contact Number.");

							} else {
								setConfirmError("Error in operation. Please contact support");
							}
						}
					});

			} else {
				let options = {
					url: makeOrRemoveLeader,
					method: "POST",
					payload: {
						contactNumber: props.contactNumber,
						isLeader: props.isLeader,
					},
					headers: {
						headers: {
							Authorization: localStorage.getItem("token")
								? `Bearer ${localStorage.getItem("token")}`
								: "",
						},
					},
				};
				HttpService(options)
					.then(() => {
						props.updateLeaderDialogStatus(false, "", null, null, false, true);
						props.setRefreshAllianceMembersDetails(true);
					})
					.catch((err) => {
						console.log("Error in handling confirm: ", err);
						if (err?.status === 403) {
							localStorage.clear();
							navigate("/");
						} else {
							setConfirmError("Error in operation. Please contact support");
						}
					});
			}
		} catch (err) {
			console.log("Error in handling confirm: ", err);
			setConfirmError("Error in operation. Please contact support");
		}
	};

	React.useEffect(() => {
		setConfirmError("")
	},[])

	var titleStyle = {
		backgroundColor: headingBlue,
		color: "white",
		fontSize: "1rem",
		display: "flex",
		alignItems: "center",
	};

	var errorStyleText = {
		color: red,
		marginTop: 1,
		fontSize: 11,
		marginLeft: 5,
		display: "flex",
		justifyContent: "center",
	};

	return (
		<>
			<Dialog
				maxWidth="xs"
				fullWidth
				open={props.leaderDialogStatus}
				onClose={() => props.updateLeaderDialogStatus()}
			>
				<DialogTitle
					sx={{ height: "1.8rem", padding: "0.4rem 0rem 0.4rem 1rem" }}
					style={titleStyle}
				>
					Confirmation
				</DialogTitle>
				<DialogContent style={{ marginTop: "1rem" }}>
					<Typography style={{ padding: "0.2rem", color: maroon }}>
						{props.confirmationMsg}
					</Typography>

					<Box style={{ textAlign: "center", marginTop: "1rem" }}>
						<Button
							style={{
								padding: "0.1rem 0.4rem 0.1rem 0.4rem",
								backgroundColor: headingBlue,
								color: "white",
							}}
							onClick={() =>
								props.isNoActionDialog
									? props.updateLeaderDialogStatus(
											false,
											"",
											null,
											null,
											false,
											false
									  )
									: handleConfirm()
							}
						>
							{props.isNoActionDialog ? "OK" : "Confirm"}
						</Button>
						<Button
							style={{
								padding: "0.1rem",
								backgroundColor: "red",
								color: "white",
								marginLeft: "0.5rem",
								border: "1px solid",
							}}
							onClick={() => {
								// setNewSessionError(null)
								// setNewSession(null)
								props.updateLeaderDialogStatus();
							}}
						>
							Close
						</Button>
					</Box>

					<Box style={{padding: '0.5rem'}}>
					<Typography style={{...errorStyleText, fontSize: '0.9rem'}}>{confirmError}</Typography>
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default ConfirmationDialogBox;
