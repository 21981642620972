import { Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTextField from "./CustomTextField";
import {
	buttonContainer,
	buttonStyle,
	customTextFieldStyle,
	errorStyleText,
} from "./formStyle";
import HttpService from "../backend/requestApis";
import { fetchWORKerDetails } from "../backend/backendApis";
import { validateContact } from "../utils/validations";
import { sendOtp } from "../utils/commonFunctions";

const GetDetails = ({
	contact,
	setContact,
	setIsVerified,
	setWORKerDetails,
	WORKerDetails,
	setSubmitError,
	setisLoader,
	isLoader,
	isOTP
}) => {
	const [contactError, setContactError] = useState(null);

	useEffect(() => {
		if (isLoader) setWORKerDetails({});
	}, [isLoader]);

	const handleContactChange = (contact) => {
		setContact(contact);
	};

	const validateGetWORKerDetails = () => {
		let isValid = true;
		let contactValidate = validateContact(contact);
		if (contactValidate != "") {
			setContactError(contactValidate);
			isValid = false;
		} else {
			setContactError("");
		}
		return isValid;
	};

	const getWORKerDetails = async () => {
		try {
			setIsVerified(false);
			setSubmitError(null);
			let isValid = await validateGetWORKerDetails();
			if (isValid) {
				setisLoader(true);
				let options = {
					url: fetchWORKerDetails,
					method: "GET",
					params: {
						contactNumber: contact,
						isRegistered: true
					},
				};
				HttpService(options)
					.then((data) => {
						setWORKerDetails(data.data.data);
						setisLoader(false);
					})
					.catch((err) => {
						console.log("Error in fetching the WORKer details.", err);
						if (err.status == 404) {
							setContactError(err?.response?.data?.description);
						}
						setisLoader(() => false);
					});
			}
		} catch (err) {
			console.log("Error in handling get WORKer Details: ", err);
			setisLoader(() => false);
		}
	};

	return (
		<>
			<Container
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					textAlign: "center",
					alignItems: "center",
					padding: "0.2rem 0.4rem 0rem 0.4rem",
				}}
			>
				<Typography style={{ padding: "0rem 0rem 0.3rem 0rem", fontSize: "1rem" }}>
					Enter Your Contact Number
				</Typography>
				<CustomTextField
					setFunc={handleContactChange}
					// style={customTextFieldStyle}
					value={contact}
				></CustomTextField>
				<Typography style={errorStyleText}>{contactError}</Typography>
				<div style={buttonContainer}>
					{
						<button
							style={buttonStyle}
							onClick={() => {
								getWORKerDetails();
							}}
						>
							Get Your Details
						</button>
					}
				</div>
				
			</Container>

			{WORKerDetails && (
				<Grid
					container
					spacing={3}
					style={{
						padding: "0.2rem",
						display: "flex",
						alignItems: "center",
						textAlign: "center",
					}}
				>
					<Grid
						item
						xs={4}
						s={4}
						style={{
							display: "flex",
							alignItems: "center",
							textAlign: "center",
						}}
					>
						<Typography
							style={{
								wordWrap: "break-word",
								whiteSpace: "normal",
							}}
						>
							{WORKerDetails.name}
						</Typography>
					</Grid>
					<Grid
						item
						xs={2}
						s={2}
						style={{
							display: "flex",
							alignItems: "center",
							textAlign: "center",
						}}
					>
						<Typography
							style={{
								wordWrap: "break-word",
								whiteSpace: "normal",
							}}
						>
							{WORKerDetails.gender}
						</Typography>
					</Grid>
					<Grid
						item
						xs={3}
						s={3}
						style={{
							display: "flex",
							alignItems: "center",
							textAlign: "center",
						}}
					>
						<Typography
							style={{
								wordWrap: "break-word",
								whiteSpace: "normal",
							}}
						>
							{WORKerDetails.district}
						</Typography>
					</Grid>
					<Grid
						item
						xs={3}
						s={3}
						style={{
							display: "flex",
							alignItems: "center",
							textAlign: "center",
						}}
					>
						<Typography
							style={{
								wordWrap: "break-word",
								whiteSpace: "normal",
							}}
						>
							{WORKerDetails.chapter}
						</Typography>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default GetDetails;
