import { headingBlue } from "../assets/colors";

export default function Header( props ){
    return(
        <>
        <nav className="navbar navbar-expand-lg" 
        style={{backgroundColor: headingBlue,
            position: 'fixed',
            top: '0rem',
            left: '0rem',
            width: '100%',
            zIndex: 1000,
            opacity: 1,
        }}>
        <div className="container-fluid" style={{display: "flex", justifyContent: "center", flexWrap: "nowrap"}}>
            <a className="navbar-brand mx-auto" style={{fontSize: "1.7rem", color: "white", textAlign: "center", padding: "0.5rem"}}>{props.title}</a>
        </div>
    </nav>
        </>
    )
}