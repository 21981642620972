import { Container, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomTextField from "./CustomTextField";
import { useNavigate } from "react-router-dom";
import HttpService from "../backend/requestApis";
import { login } from "../backend/backendApis";
import {
	buttonContainer,
	buttonStyle,
	containerStyle,
	customTextFieldStyle,
	imgContainer,
	imgStyle,
	paperStyle,
} from "./formStyle";

const ReportLoginForm = () => {
	const [contact, setContact] = useState("");
	const [password, setPassword] = useState("");
	const navigate = useNavigate();

	function handleContactChange(contact) {
		setContact(contact);
	}

	function handlePasswordChange(password) {
		setPassword(password);
	}

	function handleSignUp() {
		navigate("/report-signup");
	}

	async function handleLogin() {
		try {
			let options = {
				url: login,
				method: "POST",
				payload: {
					contact,
					password,
				},
			};

			HttpService(options).then((data) => {
				// if (data?.isAuthenticated) {
				// 	navigate("/report-forms");
				// }
				navigate("/report-forms");
			});
		} catch (err) {
			console.log("Error in handling login.", err);
			// isLoader(false);
		}
	}

	return (
		<div style={containerStyle}>
			<div style={imgContainer}>
				<img
					src={require("../assets/images/WORK_logo.png")}
					alt="Logo"
					style={imgStyle}
				></img>
			</div>
			<Paper style={paperStyle}>
				<Container
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						gap: "0.5rem",
						alignItems: "center",
						textAlign: "center",
						padding: "0.7rem 0.4rem 0rem 0.4rem",
					}}
				>
					<Typography
						style={{ padding: "0.2rem", fontSize: "1rem", fontWeight: "bold" }}
					>
						Login
					</Typography>

					<CustomTextField
						setFunc={handleContactChange}
						placeholder="Contact Number"
						value={contact}
						style={customTextFieldStyle}
					></CustomTextField>
					<CustomTextField
						setFunc={handlePasswordChange}
						placeholder="Contact Number"
						value={password}
						style={customTextFieldStyle}
					></CustomTextField>
					<div style={buttonContainer}>
						<button style={buttonStyle} onClick={handleLogin}>
							Login
						</button>
					</div>
					<div style={buttonContainer}>
						<button
							style={{ border: "none", backgroundColor: "white" }}
							onClick={handleSignUp}
						>
							Sign Up
						</button>
					</div>
				</Container>
			</Paper>
		</div>
	);
};

export default ReportLoginForm;
