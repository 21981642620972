import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	Paper,
	TableCell,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { headingBlue, lightGrey, lightRed, red } from "../assets/colors";
import CustomDropDown from "./CustomDropDown";
import {
	fetchAllianceList,
	fetchAllianceMemberDetails,
} from "../backend/backendApis";
import HttpService from "../backend/requestApis";
import Loader from "./Loader";
import SearchWORKersForAlliance from "./SearchWORKersForAlliance";
import EditAllianceConfirmationDialog from "./EditAllianceConfirmationDialog";

var titleStyle = {
	backgroundColor: headingBlue,
	color: "white",
	fontSize: "1rem",
	display: "flex",
	alignItems: "center",
};

var paperStyle = {
	marginTop: "0.7rem",
	padding: "0rem 0.5rem",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	textAlign: "center",
};

var cellStyle = {
	padding: "0.3rem 0.5rem 0.3rem 0.5rem",
	fontSize: "0.9rem",
	fontWeight: "400",
	textAlign: "center",
};

const EditAllianceDashboard = (props) => {
	const [selectedAlliance, setSeletedAlliance] = useState("Choose An Alliance");
	const [allianceList, setAllianceList] = useState([]);
	const [allianceMembersDetails, setAllianceMembersDetails] = useState([]);
	const [leaderDialogStatus, setLeaderDialogStatus] = useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [leaderContactNumber, setLeaderContactNumber] = useState(null);
	const [leaderName, setLeaderName] = useState("");
	const [leaderIsLeader, setLeaderIsLeader] = useState(null);
	const [refreshAllianceMembersDetails, setRefreshAllianceMembersDetails] =
		useState(false);
	const [openSearchMembersScreen, setOpenSearchMembersScreen] = useState(false);
	const [selectedWORKers, setSelectedWORKers] = useState([]);
	const [supervisorContact, setSupervisorContact] = useState("");
	const [selectedAllianceId, setSeletedAllianceId] = useState("");
	const [selectedLeaderContact, setSelectedLeaderContact] = useState("");
	const [isDeleteOperation, setIsDeleteOperation] = useState("");

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if( props.editAllianceDialogStatus){
			let options = {
				url: fetchAllianceList,
				method: "GET",
			};
			setIsLoading(true);
			HttpService(options)
				.then((res) => {
					setAllianceList(res.data.data);
					setIsLoading(false);
				})
				.catch((err) => {
					console.error(err);
					setIsLoading(false);
				});
			}
	}, [props.editAllianceDialogStatus]);

	async function fetchMemberDetails(allianceName){
		try{
			setIsLoading(true)
			let options = {
				url: fetchAllianceMemberDetails,
				method: "GET",
				params: {
					allianceName: allianceName,
				},
			};
	
			let res = await HttpService(options)
			const memberDetails = res.data.data.memberDetails;
			setAllianceMembersDetails(memberDetails);
			setSupervisorContact(res.data.data.supervisorContact);
			setLeaderContactNumber(memberDetails[0]['contactNumber']);
			setLeaderName(memberDetails[0]['name']);
			setIsLoading(false)
			} catch(err){
				console.log("Error in fetching member List: ", err)
				setIsLoading(false)
			}
		
	}

	const updateLeaderDialogStatus = (
		status,
		msg,
		selectedLeaderContact,
		isLeader,
		isDeleteOperation
	) => {
		setLeaderDialogStatus(status);
		setConfirmationMessage(msg);
		setSelectedLeaderContact(selectedLeaderContact);
		setLeaderIsLeader(isLeader);
		setIsDeleteOperation(isDeleteOperation);
	};

	async function updateALliance(alliance) {
		setSeletedAlliance(() => alliance.name);
		setSeletedAllianceId(() => alliance._id)
		fetchMemberDetails(alliance.name)
	}

	const updateRefreshAlliancedMemberDetails = () => {
		fetchMemberDetails(selectedAlliance)
		setOpenSearchMembersScreen(false)
	}

	return (
		<>
			<Dialog
				maxWidth="sm"
				fullWidth
				open={props.editAllianceDialogStatus}
				onClose={() => props.updateEditAllianceDialogStatus(true)}
			>
				<DialogTitle
					sx={{ height: "1.8rem", padding: "0.4rem 0rem 0.4rem 1rem" }}
					style={titleStyle}
				>
					Edit An Alliance
				</DialogTitle>
				<DialogContent>
						<>
							<Box style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: "1rem 1rem 0rem 1rem", marginTop: '0.5rem'}}>
								<CustomDropDown
									width="16rem"
									regular={true}
									list={allianceList}
									value={selectedAlliance}
									setFunc={updateALliance}
									isValueRequired={true}
									placeHolder="Choose An Alliance"
								></CustomDropDown>

							</Box>
								
								{selectedAlliance !== "Choose An Alliance" && (
									<Box  style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: "0rem 2rem 0rem 2rem", PointerEvent:!openSearchMembersScreen, opacity: openSearchMembersScreen ? 0.5 : 1 }}>
										<img
											src={require("../assets/images/add.png")}
											alt="add icon"
											style={{ width: "30px", height: "30px", marginRight: "3rem" }}
											onClick={() => 
												{setSelectedWORKers([])
												setOpenSearchMembersScreen(true)}}
										></img>
									</Box>
										
									
								)}

							{isLoading &&
								<Loader>
								</Loader>
							}
							
							{allianceMembersDetails.length > 0 && !openSearchMembersScreen && (
								<Paper
									style={{
										display: "flex",
										flexDirection: "column",
										marginTop: "0.5rem",
										maxHeight: "30vh",
										overflow: "auto"
									}}
								>
									{allianceMembersDetails.map((member) => (
										<AllianceMembersCard
											member={member}
											key={member.contactNumber}
											updateLeaderDialogStatus={updateLeaderDialogStatus}
											leaderContactNumber={leaderContactNumber}
										/>
									))}
								</Paper>
							)}

							{openSearchMembersScreen && (
									<>
										<SearchWORKersForAlliance
											setOpenSearchMembersScreen={setOpenSearchMembersScreen}
											selectedWORKers={selectedWORKers}
											setSelectedWORKers={setSelectedWORKers}
											updateRefreshAlliancedMemberDetails={
												updateRefreshAlliancedMemberDetails
											}
											supervisorContact={supervisorContact}
											leaderContactNumber={leaderContactNumber}
											leaderName={leaderName}
											selectedAllianceId={selectedAllianceId}
										/>

									</>
								)}

							<EditAllianceConfirmationDialog
									leaderDialogStatus={leaderDialogStatus}
									updateLeaderDialogStatus={updateLeaderDialogStatus}
									confirmationMsg={confirmationMessage}
									setRefreshAllianceMembersDetails={updateRefreshAlliancedMemberDetails}
									leaderContact={leaderContactNumber}
									contactNumber={selectedLeaderContact}
									selectedAllianceId={selectedAllianceId}
									leaderIsLeader={leaderIsLeader}
									isDeleteOperation={isDeleteOperation}
									allianceMembersDetails={allianceMembersDetails}
								/>
						</>
				</DialogContent>
			</Dialog>
			
		</>
	);
};

export default EditAllianceDashboard;

function AllianceMembersCard({ member, updateLeaderDialogStatus }) {
	return (
		<TableCell
			style={
				member.isLeader
					? { ...cellStyle, backgroundColor: lightGrey }
					: cellStyle
			}
		>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					gap: "1rem",
				}}
			>
				<div style={{ display: "flex", gap: "0.5rem" }}>
					<div>{member?.name}</div>
					{member?.isLeader && (
						<span
							style={{
								border: "1px dashed",
								borderColor: red,
								borderRadius: "1rem",
								padding: "0rem 0.5rem",
								color: red,
								background: lightRed,
							}}
						>
							Leader
						</span>
					)}
				</div>
				<div style={{ display: "flex", gap: "0.5rem" }}>
					{!member.isLeader && (
						<Paper
							style={{
								width: "17px",
								height: "17px",
								padding: "0.2rem 0.2rem 0.25rem 0.2rem",
							}}
							onClick={() => {
								updateLeaderDialogStatus(
									true,
									`Are you sure you want to make this member a leader of this alliance? The current will be removed as leader.`,
									member.contactNumber,
									member.isLeader,
									false
								);
							}}
						>
							<img
								src={require("../assets/images/notLeader.png")}
								alt="leader icon"
								style={{ width: "18px", height: "18px" }}
							></img>
						</Paper>
					)}

					<Paper
						style={{
							width: "17px",
							height: "17px",
							padding: "0.2rem 0.2rem 0.25rem 0.2rem",
						}}
						onClick={() => {
							updateLeaderDialogStatus(
								true,
								`Are you sure you want to delete the ${
									member.isLeader ? "leader ?" : "member ?"
								} ${
									member.isLeader
										? " If yes, select a new Leader from the below dropdown."
										: ""
								}`,
								member.contactNumber,
								member.isLeader,
								true
							);
						}}
					>
						<img
							src={require("../assets/images/delete_icons.png")}
							alt="Delete Icon"
							style={{ width: "18px", height: "18px" }}
						></img>
					</Paper>
				</div>
			</div>
		</TableCell>
	);
}
