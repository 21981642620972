import { FormControl, Grid, MenuItem, Select, styled, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import { maroon } from '../assets/colors'

function CustomTilesDropDown(props) {

  return (
    props.regular ? 

    <>
    <Select
        variant="outlined"
        value={props.value}
        style = {{textAlign: 'left', width: props.width ? props.width : "6rem", color: (props.placeHolder === props.value) ? '#B0B0B0' : 'black',  height: props.height ? props.height : "3rem", padding: "0.2rem", paddingLeft: '0rem', boxShadow: '0 0.2rem 0.4rem 0 #D3D3D3'}}
        sx={{ fontSize: '0.9rem'}}
        MenuProps={{
            PaperProps:{
                style:{
                    maxHeight: '184px',
                    width: "10rem",
                    padding: "0rem 0.3rem 0rem 0.3rem",
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '0.5rem',
                    "&::-webkit-scrollbar":{
                    display: 'none'
                  },
                  scrollbarWidth: "none",
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right', 
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                  },

                }
            }
        }}

        onChange={(e) => {
            props.isCount ?
            props.setFunc(e.target.value, props.count) :
            props.setFunc(e.target.value)
        }}
    >
        {props.placeHolder ? 
        <MenuItem value={props.value} disabled>{props.placeHolder}
        </MenuItem>:
        <></>        
        }

        
            {/* <Grid container spacing={1} style={{}}> */}
                {(props.list.map((option) => (
                    // <Grid item xs={4} sm={4} sx={{display: 'flex', justifyContent: 'center', wordWrap: true, padding: "0.1rem 0.1rem 0.1rem 0.1rem"}}>
                        <MenuItem key = {option.name} 
                            value={option.name} 
                            style={{display: 'flex', flexDirection: 'column', 
                                alignItems: 'center', justifyContent: 'center', fontSize: '0.8rem'
                            }}
                            onChange={(e) => {
                                props.setFunc(e)
                            }}
                            >
                            <Typography style={{textAlign: 'center'}}>
                                {option?.displayName?.split('/')[0]}
                            </Typography>
                            
                            <Typography style={{fontSize: '0.8rem', color: maroon, textAlign: 'center'}}>
                                {option?.displayName?.split('/')[1]}
                            </Typography>
                        </MenuItem>
                    // </Grid>
                ))
            )}
            {/* </Grid> */}
            
        

    </Select>
    
    </>
    :
    <>
        <Select
        fullWidth
        variant="outlined"
        value={props.value}
        style = {{ fontSize: "0.9rem", textAlign: 'left', color: (props.placeHolder === props.value) ? '#B0B0B0' : 'black',  height: props.height ? props.height : "2rem", padding: "0.2rem", paddingLeft: '0rem', boxShadow: '0 0.2rem 0.4rem 0 #D3D3D3'}}
        sx={{ fontSize: '0.9rem'}}
        MenuProps={{
            PaperProps:{
                style:{
                    maxHeight: '184px',
                    fontSize: "0.9rem",
                    width: props.width ? props.width : "6rem",
                    "&::-webkit-scrollbar":{
                    display: 'none'
                  },
                  scrollbarWidth: "none"

                }
            }
        }}
        onChange={(e) => 
            props.isCount ?
            props.setFunc(e.target.value, props.count) :
            props.setFunc(e.target.value)}
        displayEmpty
        renderValue={() => props.value}
        >
        {props.placeHolder ? 
        <MenuItem value={props.value ? props.value : 'Filter On'} disabled>{props.placeHolder}
        </MenuItem>:
        <></>        
        }

        { props.isValueRequired ? 
            (props?.list?.map((option) => (
                <MenuItem key = {option.name} value={option}>{option.name}</MenuItem>
            )))
        : 
            (props?.list?.map((option) => (
                <MenuItem key = {option.name} value={option.name}>{option.name}</MenuItem>
            ))
            )
        }
       </Select>
    </>
  )
}

export default CustomTilesDropDown