import firebase from 'firebase/app';
import 'firebase/auth';

const  firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

if( ! firebase.apps.length){
    try{
        firebase.initializeApp(firebaseConfig)
    } catch(err){
        console.log("Error in initialising firebase: ", err)
    }
    
}

export const auth = firebase.auth()
export const signOut = () => auth.signOut()