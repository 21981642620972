import firebase from "firebase/app";
import "firebase/auth";
import { auth, signOut } from "../utils/firebase";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

export const clearRecaptchaInstance = () => {
	return new Promise(async (resolve, reject) => {
		try {
			await window.recaptchaVerifer.clear();
			delete window.recaptchaVerifer;
			resolve();
		} catch (err) {
			reject();
		}
	});
};

export const setUpRecaptcha = async () => {
	return new Promise(async (resolve, reject) => {
		try {
			console.log("Inside Captcha verification");
			if (window.recaptchaVerifer) {
				await window.recaptchaVerifer.clear();
			}

			window.recaptchaVerifer = new firebase.auth.RecaptchaVerifier(
				"recaptcha-container",
				{
					size: "invisible",
					callback: (response) => {
						console.log("reCAPTCHA verified");
						resolve();
					},
					"expired-callback": () => {
						console.error("reCAPTCHA expired, please try again.");
						reject("reCAPTCHA expired, please try again.");
					},
				}
			);
			resolve();
			// await window.recaptchaVerifier.render().then(resolve);
		} catch (err) {
			console.log("Error in captcha: ", err);
			reject("Error in captcha verification.");
		}
	});
};

export const sendOtp = async (phoneNumber) => {
	return new Promise(async (resolve, reject) => {
		try {
			// console.log("recaptcha verifier: ", window.recaptchaVerifer)
			await setUpRecaptcha();
			// console.log("recaptcha verifier: ", window.recaptchaVerifer)
			phoneNumber = "+91" + phoneNumber;
			const appVerifier = window.recaptchaVerifer;
			const confirmationResult = await auth.signInWithPhoneNumber(
				phoneNumber,
				appVerifier
			);
			console.log("OTP sent.");
			await window.recaptchaVerifer.clear();
			// console.log("recaptcha verifier: ", window.recaptchaVerifer);
			resolve(confirmationResult);
		} catch (err) {
			console.log("Error in sending OTP:", err);

			if (err?.code) {
				reject({ msg: err.code });
			} else {
				reject({ err: err });
			}
			if (window.recaptchaVerifier) {
				await window.recaptchaVerifier.clear();
				delete window.recaptchaVerifer;
			}
		}
	});
};

export const verifyOTP = (confirmationResult, enteredOTP) => {
	return new Promise(async (resolve, reject) => {
		try {
			const userCredential = await confirmationResult.confirm(enteredOTP);

			// if (window.recaptchaVerifer) {
			//     await window.recaptchaVerifer.clear();
			//     // delete window.recaptchaVerifer
			//     // Or delete it if you no longer need it
			//     // delete window.recaptchaVerifer;
			// }

			resolve(userCredential.user._lat);
		} catch (err) {
			console.log("Error in verifying OTP: ", err);
			if (err?.code) {
				reject({ msg: err.code });
			} else {
				reject({ err: err });
			}
		}
	});
};

export const logOut = (navigate) => {
	return new Promise(async (resolve, reject) => {
		try {
			let signOutResult = await signOut();
			navigate("/signUp");
			resolve();
		} catch (err) {
			console.log("Error in signing out: ", err);
			reject("Error in signing out");
		}
	});
};

export const getUser = () => {
	return new Promise((resolve, reject) =>{
		const user = auth.currentUser;
		if(user){
			resolve(user)
		} else {
			reject()
		}
	})
}

export const getWeekforToday = () => {
	try {
		const today = dayjs();
		// console.log("todayDate: ", today);
		if (today < dayjs("2024-11-15T00:00:00"))
			return { name: "Week 0", value: 0 };
		else if (today <= dayjs("2024-11-18T00:00:00"))
			return { name: "Week 1", value: 1 };
		else if (today <= "2024-11-25T00:00:00")
			return { name: "Week 2", value: 2 };
		else if (today <= "2024-12-02T00:00:00")
			return { name: "Week 3", value: 3 };
		else if (today <= "2024-12-09T00:00:00")
			return { name: "Week 4", value: 4 };
		else if (today <= "2024-12-16T00:00:00")
			return { name: "Week 5", value: 5 };
		else if (today <= "2024-12-23T00:00:00")
			return { name: "Week 6", value: 6 };
		else if (today <= "2024-12-30T00:00:00")
			return { name: "Week 7", value: 7 };
		else if (today <= "2025-01-06T00:00:00")
			return { name: "Week 8", value: 8 };
		else if (today <= "2025-01-13T00:00:00")
			return { name: "Week 9", value: 9 };
		else if (today <= "2025-01-20T00:00:00")
			return { name: "Week 10", value: 10 };
		else if (today <= "2025-01-27T00:00:00")
			return { name: "Week 11", value: 11 };
		else if (today <= "2025-02-03T00:00:00")
			return { name: "Week 12", value: 12 };
		else if (today <= "2025-02-10T00:00:00")
			return { name: "Week 13", value: 13 };
		else if (today <= "2025-02-17T00:00:00")
			return { name: "Week 14", value: 14 };
		else if (today <= "2025-02-24T00:00:00")
			return { name: "Week 15", value: 15 };
		else if (today <= "2025-03-01T00:00:00")
			return { name: "Week 16", value: 16 };
		// else
		// return(17)
	} catch (err) {
		return 0;
	}
};

export const isDistrictDependentTask = (taskName) => {
	const tasks = [
		"joinFBGroups",
		"joinFBGroupsD2",
		"districtResearch",
		"districtResearchD2",
		"callInterestedLeads",
		"callInterestedLeadsD2",
		"promotedWeeklyDars",
		"promotedWeeklyDarsD2",
		"collectTestimonail",
		"collectTestimonailD2",
		"leadsZoomMeeting",
		"leadsZoomMeetingD2",
		"influencerOutreach",
		"influencerOutreachD2",
		"shareVideosOfAllama",
		"shareVideosOfAllamaD2",
		"remixedReelsAndShorts",
		"remixedReelsAndShortsD2",
	];

	if (tasks.includes(taskName)) return true;
	else return false;
};
