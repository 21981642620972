import { Box, Button, Chip, Dialog, DialogContent, DialogTitle, Grid, paginationClasses, Paper, Stack, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import _, { set, uniq } from "lodash";
import { headingBlue, maroon, red } from '../assets/colors'
import CustomTextField from './CustomTextField'
import CustomDropDown from './CustomDropDown'
import { createAlliance, getLeadersList, getSupervisorsList, getWORKerDetails, getWORKersForAlliance } from '../backend/backendApis';
import HttpService from '../backend/requestApis';
import { useNavigate } from 'react-router-dom';
import { validateName } from '../utils/validations';

const CreateAllianceDashboard = (props) => {

  const [textToSearch, setTextToSearch] = React.useState('')
  const [searchResult, setSearchResult] = React.useState([])
  const [selectedWORKers, setSelectedWORKers] = React.useState([])
  const [selectedWORKersError, setSelectedWORKersError] = React.useState('')
  const [isHovered, setIsHovered] = React.useState(null)
  const [leadersList, setLeadersList] = React.useState([])
  const [allianceName, setAllianceName] = React.useState('')
  const [allianceNameError, setAllianceNameError] = React.useState('')
  const [selectedLeaders, setSelectedLeaders] = React.useState('Leaders')
  const [selectedLeadersContact, setSelectedLeadersContact]= React.useState('')
  const [selectedLeaderError, setSelectedLeaderError] = React.useState('')
  const [supervisorsList, setSupervisorsList] = React.useState([])
  const [selectedSupervisorsContact, setSelectedSupervisorsContact]= React.useState('')
  const [selectedSupervisors, setSelectedSupervisor] = React.useState('Supervisors')
  const [selectedSupervisorError, setSelectedSupervisorError] = React.useState('')
  const [isNoRecordFound, setIsNoRecordFound] = React.useState(false)
  const [apiError, setApiError] = React.useState('')

  const navigate = useNavigate()

  const updateAllianceName = (allianceName) => {
    setAllianceName(allianceName)
  }

  const updateSelectedLeaders = (value) => {
    setSelectedLeaders(() => value.name)
    setSelectedLeadersContact(value.contactNumber)
  }

  async function getLeaders(supervisorContact) {
    let options = {
      url: getLeadersList,
      method: 'GET',
      params:{
        "supervisorContact": supervisorContact
      },
      headers: {
        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null
      }
    }
    HttpService(options).then(data => {
      setLeadersList(data?.data?.data)
    }).catch(err => {
      if(err?.status === 403){
        localStorage.clear()
        navigate('/')
      } else {
        setApiError('There is some error. Please contact support.')
      }
    })
    
  }

  const updateSelectedSupervisor = (value) => {
    setSelectedSupervisor(value.name)
    setSelectedSupervisorsContact(() => value.contactNumber)
    setSelectedLeaders(null)
    setSelectedLeadersContact(null)
    getLeaders(value.contactNumber)
  }

  const updateTextToSearch = (text) => {
    if( text === ''){
      setTextToSearch(text)
      setSearchResult([])
      debouncedApiCall(text, selectedSupervisorsContact)
    } else {
      setIsNoRecordFound(false)
      setTextToSearch(text)
      debouncedApiCall(text, selectedSupervisorsContact)
    }
  }

  const debouncedApiCall = useCallback(
    _.debounce((text, supervisorContact) => {
      // setTextToSearch(() => text)
      if( text != ''){
        let options= {
          url: getWORKersForAlliance,
          method: 'GET',
          params: {
            textToSearch: text,
            supervisorContact: supervisorContact
          },
          headers: {
            Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null
          }
        }
        HttpService(options).then(data => {
          if( data?.data?.description === 'No Record Found.'){
            setIsNoRecordFound(true)
          }
          setSearchResult(data?.data?.data)
        }).catch(err => {
          if(err?.status === 403){
            localStorage.clear()
            navigate('/')
          } else {
            setApiError('There is some error. Please contact support.')
            }
          })
        }
      }, 500),[]
  );

  const handleAddWorker = (WORKersArray) => {
    var uniqueArray = WORKersArray.filter(
      (obj, index, self) => index === self.findIndex((ele) => ele._id === obj._id)
    )
    setSelectedWORKers(uniqueArray)
    
  }

  const handleDelete = (WORKer) => {
    var array = selectedWORKers
    var filteredArray = array.filter((obj) => obj._id !== WORKer._id)
    setSelectedWORKers(filteredArray)

  }

  async function getSupervisors() {
    let options = {
      url: getSupervisorsList,
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null
      }
    }
    HttpService(options).then(data => {
      setSupervisorsList(data?.data?.data)
    }).catch(err => {
      if(err?.status === 403){
        localStorage.clear()
        navigate('/')
      } else {
        setApiError('There is some error. Please contact support.')
        }
    })
  }

  const handleCreateAlliance = ()=> {
    try{

      let isValid = true

      let validateAllianceResult = validateName(allianceName, 'Alliance Name')

      if( validateAllianceResult != ''){
        setAllianceNameError(validateAllianceResult)
        isValid = false
      } else {
        setAllianceNameError('')
      }

      if(!selectedLeaders || selectedLeaders === 'Leaders'){
        setSelectedLeaderError('Leader is Required')
        isValid = false
      } else {
        setSelectedLeaderError('')
      }

      if(!selectedSupervisors || selectedSupervisors === 'Supervisors'){
        setSelectedSupervisorError('Supervisors is Required')
        isValid = false
      } else {
        setSelectedSupervisorError('')
      }

      if(!selectedWORKers || selectedWORKers.length === 0){
        setSelectedWORKersError('An alliance must have atleast one WORKer.')
        isValid = false
      } else {
        setSelectedWORKersError('')
      }

      if( isValid){
        var options = {
          url: createAlliance,
          method: "POST",
          payload: {
            allianceName: allianceName,
            leader: selectedLeaders,
            leaderContact: selectedLeadersContact,
            supervisor: selectedSupervisors,
            supervisorContact: selectedSupervisorsContact,
            WORKersDetails: selectedWORKers
          },
          headers: {headers: {
            Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
          }}
        }
        HttpService(options).then(() => {
          console.log(" Alliance Created Successfully.")
          setAllianceName('')
          setSelectedLeaders('Leaders')
          setSelectedLeadersContact('')
          setSelectedSupervisor('Supervisors')
          setSelectedSupervisorsContact('')
          setSelectedWORKers([])
          props.updateCreateAllianceDialogStatus(true)
        }).catch(err => {
          console.log("Error in creating Alliance: ", err)
          if(err?.status === 403){
            localStorage.clear()
            navigate('/')
          } else {
            console.log("Error in creating Alliance : ", err, err.status)
            if( err?.status === 409){
                setApiError(err?.response?.data?.description)
            } else {
                setApiError('There is some error. Please contact support.')
    
            }
          }
        })
      }
    } catch(err){
      if(err?.status === 403){
        localStorage.clear()
        navigate('/')
      } else {
        console.log("Error in creating Alliance : ", err, err.status)
        if( err?.status === 409){
            setApiError(err?.response?.data?.description)
        } else {
            setApiError('There is some error. Please contact support.')

        }
      }
    }
  }

  React.useEffect(() => {
    try{
      if( props.createAllianceDialogBoxStatus){
        setAllianceName(() => null)
        setSelectedSupervisor('Supervisors')
        setSelectedSupervisorsContact(null)
        setSelectedSupervisorError('')
        setSelectedLeaders('Leaders')
        setSelectedLeadersContact(null)
        setSelectedLeaderError('')
        setTextToSearch('')
        setIsNoRecordFound(false)
        setApiError('')
        setSearchResult([])
        setSelectedWORKers([])
        getSupervisors()
      }
    } catch(err){
      console.log("Error in fetching the leaders and supervisors list", err)
    }
  },[props.createAllianceDialogBoxStatus])


  var titleStyle={
      backgroundColor: headingBlue,
      color: 'white',
      fontSize:'1rem',
      display: "flex",
      alignItems: 'center'
    }
  
  var paperStyle = {
    marginTop: '0.7rem',
    padding: "0.5rem 1rem 0.5rem 0.3rem",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center'
  }

  var errorStyleText = {
    color: red,
    marginTop: 1,
    fontSize: 11,
    marginLeft: 5,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    padding: "0.2rem 0rem 0rem 0rem"
	}

  return (
    <>
        <Dialog maxWidth= 'sm' fullWidth open={props.createAllianceDialogBoxStatus} onClose={() => props.updateCreateAllianceDialogStatus()}>
        <DialogTitle sx={{height: "1.8rem", padding: "0.4rem 0rem 0.4rem 1rem"  }}  style={titleStyle}>Create An Alliance</DialogTitle>
        <DialogContent style={{marginTop: '1rem'}}>
          <Grid container spacing={1} >
            <Grid item xs={6} sm={4}>
            <CustomTextField placeHolder= "Alliance Name" value={allianceName} setFunc={updateAllianceName}></CustomTextField>
            <Typography style={errorStyleText}>{allianceNameError}</Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
            <CustomDropDown placeHolder='Supervisors' list={supervisorsList} value={selectedSupervisors} setFunc={updateSelectedSupervisor} isValueRequired={true}></CustomDropDown>
            <Typography style={errorStyleText}>{selectedSupervisorError}</Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
            <CustomDropDown placeHolder='Leaders' list={leadersList} value={selectedLeaders} setFunc={updateSelectedLeaders} isValueRequired={true}></CustomDropDown>
            <Typography style={errorStyleText}>{selectedLeaderError}</Typography>
            </Grid>
            

          </Grid>
          <Paper style={paperStyle} elevation={3}>
            <Box>
              <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ width: "100%", rowGap: 1, maxHeight: "7.2rem", overflow: 'auto', "&::-webkit-scrollbar":{
                    display: 'none'
                  },
                  scrollbarWidth: "none",}}>
                {
                  
                  selectedWORKers?.map((WORKer => {
                    return(
                      <Chip
                        label={WORKer.name}
                        onClick={() => {}}
                        onDelete={() =>handleDelete(WORKer)}
                      >
                    </Chip>
                    )

                  }))
                }
              </Stack>
            </Box>
            <Box style={{padding: '1rem 0.5rem 0rem 0.5rem'}}>
              <CustomTextField placeHolder='Enter text to search' value={textToSearch} setFunc={updateTextToSearch}></CustomTextField>
              <Typography style={errorStyleText}>{selectedWORKersError}</Typography>
            </Box>
            
              <Box style={{marginTop: '0.4rem', maxHeight: "5rem", overflow: 'auto'}}
                sx={{
                  "&::-webkit-scrollbar":{
                    display: 'none'
                  },
                  scrollbarWidth: "none",
                }}
              >
                { isNoRecordFound ? 
                  <Typography style={{color: headingBlue, padding: '0.5rem'}}>No Records Found</Typography> :
                
                searchResult?.map((result, index) => {
                  return(
                    <>
                      <Grid container spacing={1}style={{marginLeft: "0.25rem", padding: '0.3rem', color: isHovered === index ? maroon : headingBlue}}
                        onMouseEnter={() => {
                          setIsHovered(index)
                        }}
                        onMouseLeave={() => {
                          setIsHovered(null)
                        }}
                        onClick={() => {
                          let finalArray = [...selectedWORKers, result]
                          handleAddWorker(finalArray)
                        }}
                      >
                        <Grid item xs={3} s={3} style={{display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center',}}>
                        <Typography style={{fontSize: '0.8rem', wordWrap: "break-word", whiteSpace: "normal", textAlign: 'center'}}>{result.name}</Typography>
                        </Grid>
                        <Grid item xs={0.5} s={0.5} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
                            <Typography style={{fontSize: '0.8rem',wordWrap: "break-word", whiteSpace: "normal", textAlign: 'center'}}>{result.gender === 'Male' ? 'M' : 'F'}</Typography>
                        </Grid>
                        <Grid item xs={2} s={2} style={{display: 'flex', alignItems: 'center', textAlign: 'center',justifyContent: 'center',}}>
                            <Typography style={{fontSize: '0.8rem',wordWrap: "break-word", whiteSpace: "normal", textAlign: 'center'}}>{result.contactNumber}</Typography>
                        </Grid>
                        <Grid item xs={3.5} s={3.5} style={{display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center',}}>
                            <Typography style={{fontSize: '0.8rem',wordWrap: "break-word", whiteSpace: "normal", textAlign: 'center'}}>{result.district}</Typography>
                        </Grid>
                        <Grid item xs={3} s={3} style={{display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center',}}>
                            <Typography style={{fontSize: '0.8rem', wordWrap: "break-word", whiteSpace: "normal", textAlign: 'center'}}>{result.chapter}</Typography>
                        </Grid>
                        </Grid>
                    </>

                  )
                  
                    

                  })
                }

              </Box>
              

          </Paper>
          
          <Typography style={{...errorStyleText, fontWeight: '550', padding: "0.5rem 0rem 0rem 0rem"}}>{apiError}</Typography>

          <Box style={{textAlign: 'center', marginTop: '0.8rem'}}>
            <Button style={{padding: '0.4rem 0.4rem 0.2rem 0.4rem', backgroundColor: headingBlue, color: 'white'}}
            onClick={handleCreateAlliance}
            >Create Alliance</Button>
                <Button style={{padding: '0.4rem 0.4rem 0.2rem 0.4rem', backgroundColor: 'red', color: 'white', marginLeft: "0.5rem", border: "1px solid"}} onClick={() => {
                    // setNewSessionError(null)
                    // setNewSession(null)
                    props.updateCreateAllianceDialogStatus()
                }}>Close</Button>
            </Box>

        </DialogContent>
        </Dialog>
            
    </>
  )
}

export default CreateAllianceDashboard