import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { containerStyle, imgContainer, imgStyle, paperStyle } from "./formStyle";
import { SummarizeOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { headingBlue, maroon, mediumGrey, yellowish } from "../assets/colors";
import HeaderBar from "../components/HeaderBar"
import { clearRecaptchaInstance } from "../utils/commonFunctions";

var rowStyle = {
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	color: headingBlue,
	curser: "pointer",
	padding: "0.5rem 0.2rem 0.2rem 0.2rem"
};



const ReportForms = () => {
	const navigate = useNavigate();

	React.useEffect(() => {
		let token = localStorage.getItem('token')
		if( !token){
			navigate('/signUp')
		}

	}, [])
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth <= 768);
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const gridColumnStyle = isMobile ? "repeat(2, 1fr)" : "repeat(1, 1fr)";

	return (
		<>
		<HeaderBar isReportForm={true}></HeaderBar>
		<div style={containerStyle}>
			<Paper
				style={{
					display: "flex",
					// gridTemplateColumns: gridColumnStyle,
					flexDirection: 'column',
					alignItems: "center",
					padding: "2rem 0rem",
				}}
			>
				<div style={{...imgContainer, padding: "0.4rem"}}>
					<img
						src={require("../assets/images/WORK_logo.png")}
						alt="Logo"
						style={imgStyle}
					></img>
				</div>
				<Grid container spacing = {1} style={{padding: "2rem 1rem 0rem 1rem"}}>
					<Grid item xs={6} sm={3}>
						<div
							style={{...rowStyle, textAlign: 'center'}}
							onClick={() => navigate("/one-time-task")}
							onMouseOver={(e) => (e.currentTarget.style.color = maroon)}
							onMouseOut={(e) => (e.currentTarget.style.color = headingBlue)}
						>
							<SummarizeOutlined sx={{ fontSize: '4rem' }} />
							<Typography style={{fontSize: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>One Time Tasks </Typography>
						</div>
					</Grid>
					<Grid item xs={6} sm={3}>
						<div
							style={{...rowStyle, textAlign: 'center'}}
							onClick={() => navigate("/daily-task")}
							onMouseOver={(e) => (e.currentTarget.style.color = maroon)}
							onMouseOut={(e) => (e.currentTarget.style.color = headingBlue)}
						>
							<SummarizeOutlined sx={{ fontSize: '4rem' }} />
							<Typography style={{fontSize: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Daily Tasks </Typography>
						</div>
					</Grid>

					<Grid item xs={6} sm={3}>
						<div
							style={{...rowStyle, textAlign: 'center'}}
							onClick={() => navigate("/weekly-task")}
							onMouseOver={(e) => (e.currentTarget.style.color = maroon)}
							onMouseOut={(e) => (e.currentTarget.style.color = headingBlue)}
						>
							<SummarizeOutlined sx={{ fontSize: '4rem' }} />
							<Typography style={{fontSize: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Weekly Tasks </Typography>
						</div>
					</Grid>
					<Grid item xs={6} sm={3} style={{pointerEvents: 'none', opacity: 0.5}}>
					<div
							style={{...rowStyle, textAlign: 'center'}}
							onClick={() => navigate("/monthly-task")}
							onMouseOver={(e) => (e.currentTarget.style.color = maroon)}
							onMouseOut={(e) => (e.currentTarget.style.color = headingBlue)}
						>
							<SummarizeOutlined sx={{ fontSize: '4rem' }} />
							<Typography style={{fontSize: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Monthly Tasks </Typography>
						</div>
						{/* <div
							style={rowStyle}
							onClick={() => navigate("/weekly-task")}
							onMouseOver={(e) => (e.currentTarget.style.color = maroon)}
							onMouseOut={(e) => (e.currentTarget.style.color = headingBlue)}
						>
							<SummarizeOutlined sx={{ fontSize: '4rem' }} />
							<Typography style={{fontSize: '1rem'}}>Weekly Tasks</Typography>
						</div> */}
					</Grid>

				</Grid>
			</Paper>
		</div>
		</>
	);
};

export default ReportForms;
