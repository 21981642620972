import {
	Box,
	Button,
	Chip,
	Grid,
	Paper,
	Stack,
	Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import CustomTextField from "./CustomTextField";
import { headingBlue, maroon, red } from "../assets/colors";
import HttpService from "../backend/requestApis";
import {
	addNewMemberIntoAlliance,
	getWORKersForAlliance,
} from "../backend/backendApis";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

const SearchWORKersForAlliance = (props) => {
	const [textToSearch, setTextToSearch] = useState("");
	const [searchResult, setSearchResult] = useState([]);
	const [isNoRecordFound, setIsNoRecordFound] = useState(false);
	const [isHovered, setIsHovered] = React.useState(null);
	const [apiError, setApiError] = React.useState("");

	const navigate = useNavigate();

	const updateTextToSearch = (text) => {
		if (text === "") {
			setTextToSearch(text);
			setSearchResult([]);
			debouncedApiCall(text);
		} else {
			setIsNoRecordFound(false);
			setTextToSearch(text);
			debouncedApiCall(text);
		}
	};

	const debouncedApiCall = useCallback(
		_.debounce((text) => {
			// setTextToSearch(() => text)
			if (text !== "") {
				let options = {
					url: getWORKersForAlliance,
					method: "GET",
					params: {
						textToSearch: text,
						supervisorContact: props.supervisorContact,
					},
					headers: {
						Authorization: localStorage.getItem("token")
							? `Bearer ${localStorage.getItem("token")}`
							: null,
					},
				};
				HttpService(options)
					.then((data) => {
						if (data?.data?.description === "No Record Found.") {
							setIsNoRecordFound(true);
						}
						setSearchResult(data?.data?.data);
					})
					.catch((err) => {
						if (err?.status === 403) {
							localStorage.clear();
							navigate("/");
						} else {
							setApiError("There is some error. Please contact support.");
						}
					});
			}
		}, 500),
		[]
	);

	const handleAddWORKersToAlliance = () => {
		let options = {
			url: addNewMemberIntoAlliance,
			method: "POST",
			payload: {
				_id: props?.selectedAllianceId,
				leader: props.leaderName,
				leaderContact: props.leaderContactNumber,
				WORKersDetails: props.selectedWORKers,
			},
			headers: {
				headers: {
					Authorization: localStorage.getItem("token")
						? `Bearer ${localStorage.getItem("token")}`
						: "",
				},
			},
		};
		HttpService(options)
			.then(() => {
				props.updateRefreshAlliancedMemberDetails(true)
			})
			.catch((err) => {
				console.log("Error in handling confirm: ", err);
			});
	};

	const handleAddWorker = (WORKersArray) => {
		var uniqueArray = WORKersArray.filter(
			(obj, index, self) =>
				index === self.findIndex((ele) => ele._id === obj._id)
		);
		props.setSelectedWORKers(uniqueArray);
	};

	const handleDelete = (WORKer) => {
		var array = props.selectedWORKers;
		var filteredArray = array.filter((obj) => obj._id !== WORKer._id);
		props.setSelectedWORKers(filteredArray);
	};

	var titleStyle = {
		backgroundColor: headingBlue,
		color: "white",
		fontSize: "1rem",
		display: "flex",
		alignItems: "center",
	};

	var paperStyle = {
		marginTop: "0.7rem",
		padding: "0.5rem 1rem 0.5rem 0.3rem",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		textAlign: "center",
	};

	var errorStyleText = {
		color: red,
		marginTop: 1,
		fontSize: 11,
		marginLeft: 5,
		display: "flex",
		justifyContent: "center",
		textAlign: "center",
		padding: "0.2rem 0rem 0rem 0rem",
	};

	return (
		<Paper style={paperStyle} elevation={3}>
			<Box>
				<Stack
					direction="row"
					spacing={1}
					flexWrap="wrap"
					sx={{
						width: "100%",
						rowGap: 1,
						maxHeight: "7.2rem",
						overflow: "auto",
						"&::-webkit-scrollbar": {
							display: "none",
						},
						scrollbarWidth: "none",
					}}
				>
					{props.selectedWORKers?.map((WORKer) => {
						return (
							<Chip
								label={WORKer.name}
								onClick={() => {}}
								onDelete={() => handleDelete(WORKer)}
							></Chip>
						);
					})}
				</Stack>
			</Box>
			<Box style={{ padding: "1rem 0.5rem 0rem 0.5rem" }}>
				<CustomTextField
					placeHolder="Enter text to search"
					value={textToSearch}
					setFunc={updateTextToSearch}
				></CustomTextField>
				{/* <Typography style={errorStyleText}>{props.selectedWORKersError}</Typography> */}
			</Box>

			<Box
				style={{ marginTop: "0.4rem", maxHeight: "5rem", overflow: "auto" }}
				sx={{
					"&::-webkit-scrollbar": {
						display: "none",
					},
					scrollbarWidth: "none",
				}}
			>
				{isNoRecordFound ? (
					<Typography style={{ color: headingBlue, padding: "0.5rem" }}>
						No Records Found
					</Typography>
				) : (
					searchResult?.map((result, index) => {
						return (
							<>
								<Grid
									container
									spacing={1}
									style={{
										marginLeft: "0.25rem",
										padding: "0.3rem",
										color: isHovered === index ? maroon : headingBlue,
									}}
									onMouseEnter={() => {
										setIsHovered(index);
									}}
									onMouseLeave={() => {
										setIsHovered(null);
									}}
									onClick={() => {
										let finalArray = [...props.selectedWORKers, result];
										handleAddWorker(finalArray);
									}}
								>
									<Grid
										item
										xs={3}
										s={3}
										style={{
											display: "flex",
											alignItems: "center",
											textAlign: "center",
											justifyContent: "center",
										}}
									>
										<Typography
											style={{
												fontSize: "0.8rem",
												wordWrap: "break-word",
												whiteSpace: "normal",
												textAlign: "center",
											}}
										>
											{result.name}
										</Typography>
									</Grid>
									<Grid
										item
										xs={0.5}
										s={0.5}
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											textAlign: "center",
										}}
									>
										<Typography
											style={{
												fontSize: "0.8rem",
												wordWrap: "break-word",
												whiteSpace: "normal",
												textAlign: "center",
											}}
										>
											{result.gender === "Male" ? "M" : "F"}
										</Typography>
									</Grid>
									<Grid
										item
										xs={2}
										s={2}
										style={{
											display: "flex",
											alignItems: "center",
											textAlign: "center",
											justifyContent: "center",
										}}
									>
										<Typography
											style={{
												fontSize: "0.8rem",
												wordWrap: "break-word",
												whiteSpace: "normal",
												textAlign: "center",
											}}
										>
											{result.contactNumber}
										</Typography>
									</Grid>
									<Grid
										item
										xs={3.5}
										s={3.5}
										style={{
											display: "flex",
											alignItems: "center",
											textAlign: "center",
											justifyContent: "center",
										}}
									>
										<Typography
											style={{
												fontSize: "0.8rem",
												wordWrap: "break-word",
												whiteSpace: "normal",
												textAlign: "center",
											}}
										>
											{result.district}
										</Typography>
									</Grid>
									<Grid
										item
										xs={3}
										s={3}
										style={{
											display: "flex",
											alignItems: "center",
											textAlign: "center",
											justifyContent: "center",
										}}
									>
										<Typography
											style={{
												fontSize: "0.8rem",
												wordWrap: "break-word",
												whiteSpace: "normal",
												textAlign: "center",
											}}
										>
											{result.chapter}
										</Typography>
									</Grid>
								</Grid>
							</>
						);
					})
				)}
			</Box>

			<div style={{ display: "flex", gap: "0.5rem", justifyContent: "center" }}>
				<Button
					style={{
						padding: "0.1rem 0.4rem 0.1rem 0.4rem",
						margin: "0.5rem 0rem 0rem 0rem",
						backgroundColor: headingBlue,
						color: "white",
					}}
					onClick={() => {
						handleAddWORKersToAlliance();
					}}
				>
					Save
				</Button>
				<Button
					style={{
						padding: "0.1rem 0.4rem 0.1rem 0.4rem",
						margin: "0.5rem 0rem 0rem 0rem",
						backgroundColor: "red",
						width: "5rem",
						color: "white",
						border: "1px solid",
					}}
					onClick={() => {
						props.setOpenSearchMembersScreen(false);
						props.setSelectedWORKers([]);
					}}
				>
					Cancel
				</Button>
			</div>
		</Paper>
	);
};

export default SearchWORKersForAlliance;
