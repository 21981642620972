import { headingBlue, pureWhite, red } from "../assets/colors";

export var commonErrorStyleText = {
    color: red,
    marginTop: 1,
    fontSize: 11,
    marginLeft: 5,
    fontSize: "0.8rem"
}

export var buttonStyle = {
    color: pureWhite,
    backgroundColor: headingBlue,
    border: "1px solid #D9D9D9",
    marginRight: "0.3rem",
    fontFamily: "Helvetica",
    fontWeight: "500",
    fontSize: "0.9rem",
};