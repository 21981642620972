import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import React from 'react'
import { headingBlue, red } from '../assets/colors'
import CustomDropDown from './CustomDropDown'
import { createNewLeaderAccount, fetchLeadersWithoutAcc, getLeadersList, getSupervisorsList, updateLeader } from '../backend/backendApis'
import HttpService from '../backend/requestApis'
import { useNavigate } from 'react-router-dom'
import CustomTextField from './CustomTextField'
import { validateEmail } from '../utils/validations'
import Loader from './Loader'

const CreateLeaderAccount = (props) => {
  const navigate = useNavigate()

  const [supervisorsList, setSupervisorsList] = React.useState([])
  const [selectedSupervisor, setSelectedSupervisor] =React.useState('Supervisors')
  const [selectedSupervisorsContact, setSelectedSupervisorsContact]= React.useState('')
  const [selectedSupervisorError, setSelectedSupervisorError] = React.useState('')
  const [leadersList, setLeadersList] = React.useState([])
  const [selectedLeaders, setSelectedLeaders] =React.useState('Leaders')
  const [selectedLeadersContact, setSelectedLeadersContact]= React.useState('')
  const [selectedLeaderError, setSelectedLeaderError] = React.useState('')
  const [email, setEmail] = React.useState(null)
  const [emailError, setEmailError] = React.useState(null)
  const [apiError, setApiError] = React.useState('')
  const [isLoader, setIsLoader] = React.useState(null)

  async function getLeaders(supervisorContact) {
    let options = {
      url: fetchLeadersWithoutAcc,
      method: 'GET',
      params:{
        "supervisorContact": supervisorContact
      },
      headers: {
        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null
      }
    }
    HttpService(options).then(data => {
      setLeadersList(data?.data?.data)
    }).catch(err => {
      if(err?.status === 403){
        localStorage.clear()
        navigate('/')
      } else {
        setApiError('There is some error. Please contact support.')
      }
    })
    
  }

  const UpdateSelectedSupervisor = (value) => {
    setSelectedSupervisor(value.name)
    setSelectedSupervisorsContact(value.contactNumber)
    setSelectedLeaderError(null)
    setSelectedLeadersContact(null)
    getLeaders(value.contactNumber)
  }

  async function getSupervisors() {
    let options = {
      url: getSupervisorsList,
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null
      }
    }
    HttpService(options).then(data => {
      setSupervisorsList(data?.data?.data)
    }).catch(err => {
      if(err?.status === 403){
        localStorage.clear()
        navigate('/')
      } else {
        setApiError('There is some error. Please contact support.')
        }
    })
  }

  const updateSelectedLeader = (value) => {
    setSelectedLeaders(value.name)
    setSelectedLeadersContact(value.contactNumber)
  }

  const updateEmail = (email) => {
    setEmail(email)
  }

  const handleCreateLeader= () => {
    try{
      setIsLoader(true)
      let isValid = true

      if(!selectedSupervisor || selectedSupervisor === "Supervisors"){
        setSelectedSupervisorError('Supervisor is Required.')
        isValid = false
      } else {
        setSelectedSupervisorError("")
      }

      if( !selectedLeaders || selectedLeaders === "Leaders"){
        setSelectedLeaderError('Leader is Required.')
        isValid = false
      } else {
        setSelectedLeaderError('')
      }

      let validateEmailResult = validateEmail(email, 'Email')
      if( validateEmailResult != ''){
        setEmailError(validateEmailResult)
        isValid = false
      } else {
        setEmailError('')
      }

      if(isValid){
        let options = {
          url: createNewLeaderAccount,
          method: "POST",
          payload: {
            contactNumber: selectedLeadersContact,
            email: email,
            name: selectedLeaders
          },
          headers: {headers: {
						Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
					}}
        }
        HttpService(options).then(() => {
          setIsLoader(false)
          props.updateCreateLeaderAccDialogStatus()
        }).catch(err => {
          setIsLoader(false)
          console.log("Error in creating leader account:", err)
          if(err?.status === 403){
            localStorage.clear()
            navigate('/')
          } else {
            console.log("Error in fetching WORKer details.", err)
            if( err?.status === 409){
                setApiError(err?.response?.data?.description)
            } else if(err?.status === 600){
              setApiError(err?.response?.data?.description)
            } else {
                setApiError('There is some error. Please contact support.')
            }
          }
        })
      } else {
        setIsLoader(false)
      }
    } catch(err){
      console.log("Error in adding new supervisor: ", err)
      setIsLoader(false)
    }
  }

  React.useEffect(() => {
    if( props.createLeaderAccDialogStatus){
        setSelectedSupervisor('Supervisors')
        setSelectedSupervisorsContact(null)
        setSelectedSupervisorError('')
        setSelectedLeaders('Leaders')
        setSelectedLeadersContact(null)
        setSelectedLeaderError('')
        setEmail(null)
        setEmailError('')
        getSupervisors()
    }

  }, [props.createLeaderAccDialogStatus])

  var titleStyle={
    backgroundColor: headingBlue,
    color: 'white',
    fontSize:'1rem',
    display: "flex",
    alignItems: 'center'
  }

  var errorStyleText = {
    color: red,
    marginTop: 1,
    fontSize: 11,
    marginLeft: 5
	}

  return (
    <>
      <Dialog maxWidth = 'xs' fullWidth open={props.createLeaderAccDialogStatus} onClose={() => props.updateCreateLeaderAccDialogStatus()}>
      <DialogTitle sx={{height: "1.8rem", padding: "0.4rem 0rem 0.4rem 1rem"  }}  style={titleStyle}> Create Leader Account </DialogTitle>
      <DialogContent style={{marginTop: '1rem'}}>
        {/* <Box style={{display: 'flex', justifyContent: 'center', flexDirection: "row", alignItems: 'center'}}>
          <CustomDropDown></CustomDropDown>
          <CustomDropDown></CustomDropDown>

        </Box> */}
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6}>
            <CustomDropDown placeHolder='Supervisors' value={selectedSupervisor} list={supervisorsList} setFunc={UpdateSelectedSupervisor} isValueRequired={true}></CustomDropDown>
            <Typography style={errorStyleText}>{selectedSupervisorError}</Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <CustomDropDown placeHolder='Leaders' value={selectedLeaders} list={leadersList} isValueRequired={true} setFunc={updateSelectedLeader}></CustomDropDown>
            <Typography style={errorStyleText}>{selectedLeaderError}</Typography>
          </Grid>

        </Grid>
        <Box style={{display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', padding: "1.5rem 1rem 1rem 1rem"}}>
          <CustomTextField width="10rem" placeHolder="Leader's email" value={email} setFunc={updateEmail}></CustomTextField>
          <Typography style={errorStyleText}>{emailError}</Typography>
          <Button style={{marginTop: '1rem', padding: '0.4rem 0.4rem 0.2rem 0.4rem', backgroundColor: headingBlue, color: 'white'}}
            onClick={() => {
              handleCreateLeader()
            }}
          >Create Account</Button>
          <Typography style={{...errorStyleText, marginTop: '1rem'}}>{apiError}</Typography>
        </Box>
        {
          isLoader && <Loader></Loader>
        }

      </DialogContent>
      </Dialog>
    </>
  )
}

export default CreateLeaderAccount