import { Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoButton from "./InfoButton";
import { headingBlue, lightRed, maroon, pureWhite } from "../assets/colors";
import { isDistrictDependentTask } from "../utils/commonFunctions";

var checkboxRowStyle = {
	display: "flex",
	alignItems: "center",
};

const CustomCheckbox = ({
	label,
	taskId,
	description,
	isCheckboxChecked,
	setIsCheckboxChecked,
	districts,
	taskName,
}) => {
	const [isDistrictDependent, setIsDistrictDependent] = useState(false);
	const [districtName, setDistrictName] = useState("");

	useEffect(() => {
		if (districts !== null && isDistrictDependentTask(taskName)) {
			setIsDistrictDependent(true);
			setDistrictName(
				taskName.endsWith("D2")
					? districts.secondDistrict
					: districts.firstDistrict
			);
		} else {
			setIsDistrictDependent(false);
			setDistrictName("");
		}
	}, [districts, taskName]);

	//if gender is male and task is district dependent and taskName endsWith  D2 then return
	if (
		districts === null &&
		isDistrictDependentTask(taskName) &&
		taskName.endsWith("D2")
	) {
		return;
	}

	return (
		<div style={{ display: "flex", flexDirection: "column" }}>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					gap: "0.5rem",
					justifyContent: "space-between",
					fontSize: "1rem",
				}}
			>
				<div style={checkboxRowStyle}>
					<Checkbox
						checked={isCheckboxChecked || false}
						onChange={() => setIsCheckboxChecked()}
					/>
					<label
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							gap: "0.5rem",
							color: headingBlue,
						}}
					>
						<span>{label}</span>
						{isDistrictDependent && (
							<span
								style={{
									border: `1px dashed`,
									borderRadius: "0.8rem",
									padding: "0.2rem 0.4rem 0.2rem 0.4rem",
									borderColor: maroon,
									backgroundColor: lightRed,
									fontSize: '0.8rem'
								}}
							>
								{districtName}
							</span>
						)}
					</label>
				</div>
				<InfoButton.Toggle id={taskId} />
				<InfoButton.Window id={taskId}>
					<div
						style={{
							width: "18rem",
							zIndex: 9999,
							backgroundColor: pureWhite,
							border: "1px solid lightgrey",
							color: headingBlue,
							borderRadius: "0.3rem",
							padding: "0.5rem",
							fontSize: "0.9rem",
						}}
					>
						{description}
					</div>
				</InfoButton.Window>
			</div>
			{/* {isButtonSubmit && !isCheckboxChecked && (
				<p
					style={{
						margin: "0rem",
						fontSize: "0.9rem",
						color: red,
						marginLeft: "2.8rem",
					}}
				>
					required*
				</p>
			)} */}
		</div>
	);
};

export default CustomCheckbox;
