import React, { useEffect, useState } from "react";
import {
	boxStyle,
	buttonContainer,
	ButtonStyle,
	buttonStyle,
	containerStyle,
	customTextFieldStyle,
	errorStyleText,
	imgContainer,
	imgStyle,
	paperStyle,
} from "./formStyle";
import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import CustomTextField from "./CustomTextField";
import GetDetails from "./GetDetails";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import { createMemberAccount, login, memberLogin, signup } from "../backend/backendApis";
import HttpService from "../backend/requestApis";
import { headingBlue, maroon, mediumGrey, pureWhite, yellowish } from "../assets/colors";
import {
	clearRecaptchaInstance,
	getUser,
	sendOtp,
	setUpRecaptcha,
	verifyOTP,
} from "../utils/commonFunctions";
import firebase from "firebase/app";
import "firebase/auth";
import { auth } from "../utils/firebase";
import CustomDropDown from "./CustomDropDown";
import CustomPasswordTextField from "./CustomPasswordTextField";
import { commonErrorStyleText } from "../utils/commonCSS";

const ReportSignup = () => {
	const [contact, setContact] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [newPasswordError, setNewPasswordError] = useState('')
	const [confirmPassword, setConfirmPassword] = useState("");
	const [confirmPasswordError, setConfirmPasswordError] = useState('')
	const [pwdNotMatchingError, setPwdNotMatchingError] = useState('')
	const [isVerified, setIsVerified] = useState(false);
	const [WORKerDetails, setWORKerDetails] = useState({});
	const [submitError, setSubmitError] = useState(null);
	const [isLoader, setisLoader] = useState(false);
	const [isInternalLoader, setIsInternalLoader] = useState(false);
	const [isOTPLoader, setIsOTPLoader] = useState(false);
	const [OTP, setOTP] = useState(null);
	const [isOTPSent, setIsOtpSent] = useState(false);
	const [isOTPError, setIsOTPError] = useState(true);
	const [OTPErrorMsg, setOTPErrorMsg] = useState("");
	const [isOTPExpired, setIsOTPExpired] = useState(false);
	const [confirmationResult, setConfirmationResult] = useState(null);
	const [isOTPVerified, setIsOTPVerified] = React.useState(false)
	const [password, setPassword] = React.useState('')
	const [passwordError, setPasswordError] = React.useState('')

	const navigate = useNavigate();

	const updateOTP = (otp) => {
		setOTP(otp);
	};

	function updatePassword(password){
		setPassword(password)
	}

	function handleLogin(){
		try{
			setIsOTPLoader(true)
			let isValid = true

			if(!password){
				isValid = false
				setPasswordError('Password is required')
			}else {
				setPasswordError('')
			}

			if(isValid){
				let options = {
					url: memberLogin,
					method: 'POST',
					payload: {
						contact: contact,
						password: password
					}
				}
				HttpService(options).then(async (data) => {
					setIsOTPLoader(false)
					console.log("data: ", data)
					await localStorage.setItem('token', data?.data?.data?.token)
					await localStorage.setItem('contact', data?.data?.data?.contact)
					navigate('/reportForm')
				}).catch(err => {
					setIsOTPLoader(false)
					if( err?.status === 409){
						console.log("Inside.....")
						setPasswordError(() => 'Invalid Password')
					} else {
						setPasswordError('Error in Login. Please Contact Support.')
					}
					
				})
			} else {
				setIsOTPLoader(false)
			}
		}catch(err){
			console.log("Error in Login: ", err)
			setIsOTPLoader(false)
			setPasswordError('Error in Login. Please Contact Support.')
		}
	}

	function handleNewPasswordChange(password) {
		setNewPassword(password);
	}

	function handleConfirmPasswordChange(password) {
		setConfirmPassword(password);
	}

	async function handleSignUp() {
		try {
			setIsOTPLoader(true)
			let isValid = true

			if( !newPassword){
				setNewPasswordError('Password is required')
				isValid = false
			} else {
				setNewPasswordError('')
			}

			if( !confirmPassword){
				isValid = false
				setConfirmPasswordError('Confirm Password is required.')
			} else {
				setConfirmPasswordError('')
			}

			if ( newPassword != confirmPassword){
				isValid = false
				setPwdNotMatchingError('Unmatched Passwords')
			} else {
				setPwdNotMatchingError('')
			}

			if(isValid){
				let user = await getUser()
				let options = {
					url: createMemberAccount,
					method: "POST",
					payload: {
						password: newPassword,
						name: WORKerDetails.name,
						gender: WORKerDetails.gender,
						contact: WORKerDetails.contactNumber,
						designation: 'member'
					},
					headers: {headers: {
						Authorization: user._lat ? `Bearer ${user._lat}` : null
					}}
				};
				HttpService(options).then(() => {
					console.log('Member Account created successfully.')
					let options = {
						url: memberLogin,
						method: 'POST',
						payload: {
							contact: contact,
							password: newPassword
						}
					}
					HttpService(options).then(async (data) => {
						let options = {
							url: memberLogin,
							method: 'POST',
							payload: {
								contact: contact,
								password: newPassword
							}
						}
						HttpService(options).then(async (data) => {
							setIsOTPLoader(false)
							await localStorage.setItem('token', data?.data?.data?.token)
							await localStorage.setItem('contact', data?.data?.data?.contact)
							navigate('/reportForm')
						}).catch(err => {
							setIsOTPLoader(false)
							if( err?.status === 409){
								setPwdNotMatchingError(() => 'Invalid Password')
							} else {
								setPwdNotMatchingError('Error in Login. Please Contact Support.')
							}
						})
					}).catch(err => {
						setIsOTPLoader(false)
						if( err?.status === 409){
							setPwdNotMatchingError(() => 'Invalid Password')
						} else {
							setPwdNotMatchingError('Error in Login. Please Contact Support.')
						}
						
					})
				}).catch(err => {
					setIsOTPLoader(false)
					if(err?.status === 403){
						localStorage.clear()
						navigate('/signUp')
					  } else
						if( err?.status === 409){
							setPwdNotMatchingError('Account already exists.')
						} else {
							setPwdNotMatchingError('There is some error. Please contact support.')
						}
				} )				
			}else{
				setIsOTPLoader(false)
			}
	
			
		} catch (err) {
			setIsOTPLoader(false)
			console.log("Error in handling signup.", err);
			// isLoader(false);
		}
	}

	React.useEffect(() => {
		setIsOtpSent(false);
		setIsOTPError(false);
		setIsOTPVerified(false)
		setNewPasswordError(null)
		setConfirmPasswordError(null)
		setPwdNotMatchingError(null)
		setPassword(null)
		setPasswordError(null)
		setNewPassword(null)
		setConfirmPassword(null)
	}, [WORKerDetails]);

	React.useEffect(() => {
		async function getToken(){
		setisLoader(true);
		let token = await localStorage.getItem("token");
		setisLoader(false);
		if (token) {
			navigate("/reportForm");
		}
	}
	getToken()
	}, []);

	return (
		<>
			{isLoader ? (
				<Loader />
			) : (
				<div style={containerStyle}>
					<div id="recaptcha-container"></div>
					<div style={imgContainer}>
						<img
							src={require("../assets/images/WORK_logo.png")}
							alt="Logo"
							style={imgStyle}
						></img>
					</div>
					<Paper style={paperStyle}>
						<Container
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								gap: "0.5rem",
								alignItems: "center",
								textAlign: "center",
								padding: "0.4rem 0.4rem 0rem 0.4rem",
							}}
						>
							<Typography
								style={{
									padding: "0.2rem",
									fontSize: "1.2rem",
									fontWeight: "bold",
									color: yellowish,
								}}
								// onClick={handleSignUp}
							>
								Sign Up/ Login
							</Typography>
							{isInternalLoader && <Loader></Loader>}
							<GetDetails
								contact={contact}
								setContact={setContact}
								setIsVerified={setIsVerified}
								WORKerDetails={WORKerDetails}
								setWORKerDetails={setWORKerDetails}
								setSubmitError={setSubmitError}
								setisLoader={setIsInternalLoader}
								isLoader={isInternalLoader}
							/>

							{Object.keys(WORKerDetails).length > 0 && (
								<>
									{isOTPLoader && <Loader></Loader>}
									{!isOTPSent && !isOTPError && (
										<>
											{WORKerDetails.isRegistered ?
												<Box style={{display: 'flex', alignItems: "center", flexDirection: 'column', padding: '0rem 0rem 1rem 0rem'}}>
													<CustomPasswordTextField placeHolder='Enter Your Password' value={password} setFunc={updatePassword}></CustomPasswordTextField>
													<Typography style={{...errorStyleText, fontSize: '0.8rem'}}>{passwordError}</Typography>
													 <Button variant='outlined' sx={{ backgroundColor: headingBlue, width: "6rem", border: "1px solid #D9D9D9", marginTop: '0.6rem', fontWeight: "500", fontSize: "0.9rem", color: pureWhite, padding: '0.1rem 0.3rem 0.1rem 0.3rem'}}
													 	onClick={handleLogin}
													 >
														Login
													</Button>

												</Box>
												
											:
											<>
											{isOTPExpired && (
												<Typography
													style={{
														...errorStyleText,
														fontSize: "0.9rem",
														fontWeight: 550,
													}}
												>
													OTP expired. Try again
												</Typography>
											)}
											<button
												style={{
													...buttonStyle,
													backgroundColor: isOTPSent ? mediumGrey : headingBlue,
												}}
												disabled={isOTPSent}
												onClick={async () => {
													try {
														setIsOTPLoader(true);
														let sendOTPResult = await sendOtp(contact);
														setConfirmationResult(sendOTPResult);
														setIsOTPLoader(false);
														setIsOtpSent(true);
													} catch (err) {
														setIsOTPLoader(false);
														console.log(
															"Error in sending OTP:::::::::::::::::::::::::::::: ",
															err,
															err.msg
														);
														setIsOTPError(true);
														if (err.msg && err.msg === "auth/too-many-requests")
															setOTPErrorMsg(
																"Too many attempts, try again later after sometime."
															);
														else
															setOTPErrorMsg("Please refresh and try again.");
													}
												}}
											>
												GET OTP
											</button>
											</>
											}
											{/* <div id="recaptcha-container"></div> */}
										</>
									)}

									{isOTPSent && !isOTPVerified && (
										<>
											<Box style={boxStyle}>
												<Typography
													style={{ padding: "0.3rem", color: yellowish }}
												>
													OTP sent Successfully.
												</Typography>
												<CustomTextField
													placeHolder="Enter the OTP"
													value={OTP}
													setFunc={updateOTP}
												></CustomTextField>
												<Box style={{padding: '0rem'}}>
													<button
														style={buttonStyle}
														onClick={async () => {
															try {
																setOTPErrorMsg('')
																setIsOTPLoader(true);
																let token = await verifyOTP(
																	confirmationResult,
																	OTP
																);
																// await clearRecaptchaInstance()
																setIsOTPLoader(false);
																if (token) {
																	// setConfirmationResult(null)
																	await localStorage.setItem("fToken", token);
																	setIsOTPVerified(true)
																	// navigate("/reportForm");
																}
															} catch (err) {
																setIsOTPLoader(false);
																console.log("Error: ", err);
																if (
																	err?.msg &&
																	err?.msg === "auth/invalid-verification-code"
																) {
																	setIsOTPError(true);
																	setOTPErrorMsg("Wrong OTP");
																} else if (
																	err?.msg &&
																	err?.msg === "auth/code-expired"
																) {
																	setIsOtpSent(false);
																	setIsOTPError(false);
																	setIsOTPExpired(true);
																	setOTPErrorMsg("OTP expired.");
																} else {
																	console.log("error: ", err);
																	setIsOTPError(true);
																	setOTPErrorMsg(
																		"Error in verifying OTP. Please refresh and try again."
																	);
																}
															}
														}}
													>
														SUBMIT
													</button>
												</Box>
											</Box>
										</>
									)}
									{isOTPError && (
										<>
											<Box style={{padding: '0.5rem'}}>
												<Typography
													style={{ ...commonErrorStyleText, fontSize: "0.9rem" }}
												>
													{OTPErrorMsg}
												</Typography>
											</Box>
										</>
									)}

									{ isOTPVerified && 
										<>
											<Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0.5rem'}}>
												<CustomPasswordTextField placeHolder='Enter Password' value= {newPassword} setFunc={handleNewPasswordChange}></CustomPasswordTextField>
												<Typography style={commonErrorStyleText}>{newPasswordError}</Typography>
												<CustomPasswordTextField placeHolder= 'Confirm Password' value={confirmPassword} setFunc={handleConfirmPasswordChange}></CustomPasswordTextField>
												<Typography style={commonErrorStyleText}>{confirmPasswordError}</Typography>
												<Typography style={{...commonErrorStyleText, fontSize: '0.9rem'}}>{pwdNotMatchingError}</Typography>
												<Button variant='outlined' sx={{ backgroundColor: headingBlue, width: "6rem", border: "1px solid #D9D9D9", marginTop: '0.6rem', fontWeight: "500", fontSize: "0.9rem", color: pureWhite, padding: '0.1rem 0.3rem 0.1rem 0.3rem'}}
													onClick={handleSignUp}
												>
													SignUp
												</Button>
											</Box>
											
										</>
									}
								</>

								// <div style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}>
								// 	<CustomTextField
								// 		setFunc={handleNewPasswordChange}
								// 		placeholder="Contact Number"
								// 		value={newPassword}
								// 		style={customTextFieldStyle}
								// 	></CustomTextField>
								// 	<CustomTextField
								// 		setFunc={handleConfirmPasswordChange}
								// 		placeholder="Contact Number"
								// 		value={confirmPassword}
								// 		style={customTextFieldStyle}
								// 	></CustomTextField>
								// 	<div style={buttonContainer}>
								// 		<button style={buttonStyle}>Sign Up</button>
								// 	</div>

								// 	<div style={buttonContainer}>
								// 		<button
								// 			style={{ border: "none", backgroundColor: "white" }}
								// 			onClick={handleLogin}
								// 		>
								// 			Login
								// 		</button>
								// 	</div>
								// </div>
							)}
						</Container>
					</Paper>
				</div>
			)}
		</>
	);
};

export default ReportSignup;
