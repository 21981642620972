const validateContact = (contact) => {
    const phoneRegex = /^[0-9]{10}$/; // Example for a 10-digit phone number
    if (!contact) {
        return "Contact Number is required";
    } else if (!phoneRegex.test(contact)) {
        return "Contact Number is not valid";
    }
    return "";
};

const validateState = (state) => {
    if(!state){
        return 'State is required.'
    }
    return ''
}

const validateDistrict = (district) => {
    if(!district){
        return 'District is required.'
    }
    return ''
}

const validateEmail = (email, text="Username") => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(!email || email === ""){
        return `${text} is required`
    } else if (!emailRegex.test(email)){
        return `Invalid ${text}`
    }
    return ""
}

const validatePassword = (password) => {
    if(!password || password === ""){
        return "Password is required"
    }
    return ""
}

const validateName = (input, field) => {
    let specialChars = /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (!input || input === ''){
        return (field + ' is required')
    } else
    if( specialChars.test( input)){
        return ('Special character not allowed except _')
    }
    return ""
}


module.exports = {
    validateContact,
    validateState,
    validateDistrict,
    validateEmail,
    validatePassword,
    validateName
}