import { TextField } from '@mui/material'
import React from 'react'

function CustomTextField(props) {
    {
      return props.regular ? (
        <>
          <TextField 
            variant="outlined"
            fullWidth
            placeholder={props.placeHolder ? props.placeHolder : ""}
            value={props.value ? props.value: ""}
            InputProps={{
                style: { height: "2rem", padding: "0.2rem", paddingLeft: '0rem', boxShadow: '0 0.2rem 0.4rem 0 #D3D3D3'},
                sx:{ 
                  fontSize: '1rem'
                }
            }}
            InputLabelProps={{
                sx: { fontSize: '1rem'}
            }}
            onChange={(e) => {
                props.setFunc(e.target.value)
            }}
        >
        </TextField>
        </>
      ) : 
      (
        <>
          <TextField 
            variant="outlined"
            // fullWidth
            placeholder={props.placeHolder ? props.placeHolder : ""}
            value={props.value ? props.value: ""}
            InputProps={{
                style: { height: "2rem", padding: "0.2rem", paddingLeft: '0rem', boxShadow: '0 0.2rem 0.4rem 0 #D3D3D3'},
                sx:{ 
                  fontSize: '1rem'
                }
            }}
            InputLabelProps={{
                sx: { fontSize: '1rem'}
            }}
            onChange={(e) => {
                props.setFunc(e.target.value)
            }}
        >
        </TextField>
        </>

      )
    }
}

export default CustomTextField