import { Backdrop, Box, Paper } from "@mui/material";
import React from "react";
import SpinningCircles from "react-loading-icons/dist/esm/components/spinning-circles";
import { headingBlue } from "../assets/colors";

export default function Loader() {
	return(
		<>
		<Backdrop
			style={{ color:"black", zIndex: 10000000}}
			open={true}>
			<Box
				style={{ zIndex: 10000000}}
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					'& > : not(style)': {
						m: 1,
						width: 250,
						height: 125
					}
				}}
			>
				<Paper style={{height: "80px", width: "80px", textAlign: "center", color: headingBlue, backgroundColor: headingBlue, opacity: 1}}>
					<div style={{ paddingTop: "15px"}}>
						<SpinningCircles style={{ height: "50px", width: "50px"}} stroke={"white"} fill={"white"} strokeWidth={2}></SpinningCircles>
					</div>
					{/* <div style={{ marginTop: "8px",fontFamily: "PlayFair Display", fontWeight: 100, fontSize: "17px", padding: "0.1rem 0.9rem 0.5rem 0.9rem", color: "white"}}>
						Loading Data
					</div> */}
				</Paper>
			</Box>
		</Backdrop>
		</>
	)
}