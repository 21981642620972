import React from 'react'
import { headingBlue } from '../assets/colors'
import { Box, FormControl, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const CustomPasswordTextField = (props) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => 
        setShowPassword(() => !showPassword);

  return (
    <Box style={{padding: "0.3rem"}}>
            <FormControl
              sx={{width: '14rem'}} 
              variant="outlined"
            >
              {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
              <OutlinedInput
                // id="outlined-adornment-password"
                autoComplete='new-password'
                value={props.value}
                type={showPassword ? 'text' : 'password'}
                placeholder={props.placeHolder}
                sx={{
                  height: '2rem', // Set the desired height here
                  // width: '13.4rem', // Optionally override width here if needed
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    //   onMouseDown={handleMouseDownPassword}
                    //   onMouseUp={handleMouseUpPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility style={{color:headingBlue}} /> : <VisibilityOff /> }
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e) => {
                    props.setFunc(e.target.value)
                }}
                
              />
            </FormControl>
            {/* { passwordError ? 
                <Typography style={errorTextStyle}> {passwordError}</Typography>
                : <></>
            } */}
            </Box>
  )
}

export default CustomPasswordTextField