import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DistrictSelectionForm from "./components/DistrictSelectionForm";
import Dashboard from "./components/DashBoard";
import LoginScreen from "./components/LoginScreen";
import ReportLoginForm from "./components/ReportLoginFrom";
import ReportSignup from "./components/ReportSignup";
import ReportForms from "./components/ReportForms";
import OneTimeTaskReportForm from "./components/OneTimeTaskReportForm";
import DailyTaskReportForm from "./components/DailyTaskReportForm";
import MonthlyTaskReportForm from "./components/MonthlyTaskReportForm";
import WeeklyTaskReportForm from "./components/WeeklyTaskReportForm";
import ReportDashboard from "./components/ReportDashBoard";
import HomePage from "./components/HomePage";
import DailyTaskContentSheet from "./components/DailyTaskContentSheet";

function App() {
	return (
		<>
			<div>
				<BrowserRouter>
					<Routes>
						<Route
							path="/dsf"
							element={<DistrictSelectionForm></DistrictSelectionForm>}
						></Route>
						{/* <Route path="/adminDashboard" element={<Dashboard></Dashboard>}></Route> */}
						<Route path="/Login" element={<LoginScreen></LoginScreen>}></Route>
						<Route path="/" element={<HomePage />}></Route>
						<Route path="/report-login" element={<ReportLoginForm />}></Route>
						<Route path="/signup" element={<ReportSignup />}></Route>
						<Route path="/reportForm" element={<ReportForms />}></Route>
						{/* <Route path="/dashboard" element={<ReportDashboard />}></Route> */}
						<Route
							path="/dashboard"
							element={<ReportDashboard></ReportDashboard>}
						></Route>
						<Route
							path="/one-time-task"
							element={<OneTimeTaskReportForm />}
						></Route>
						<Route
							path="/monthly-task"
							element={<MonthlyTaskReportForm />}
						></Route>
						<Route
							path="/weekly-task"
							element={<WeeklyTaskReportForm />}
						></Route>
						<Route path="/daily-task" element={<DailyTaskReportForm />}></Route>
						<Route
							path="/content-sheet"
							element={<DailyTaskContentSheet />}
						></Route>
					</Routes>
				</BrowserRouter>
			</div>
		</>
	);
}

export default App;
