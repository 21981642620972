export const baseURL = process.env.REACT_APP_BASE_URL;

export const fetchWORKerDetails = "/fetchWORKerDetails";
export const fetchStates = "/fetchStates";
export const fetchDistricts = "/fetchDistricts";
export const addNewDistrictLeader = "/addNewDistrictLeader";
export const fetchSupervisorDetailsForDistrict =
	"/fetchSupervisorDetailsForDistrict";

export const getWORKerDetails = "/getWORKerDetails";
export const fetchAssignmentTableData = "/fetchTableData";
export const makeOrRemoveLeader = "/makeOrRemoveLeader";
export const addNewSupervisor = "/addNewSupervisor";
export const getWORKersForAlliance = "/getWORKersForAlliance";
export const getLeadersList = "/getLeadersList";
export const getSupervisorsList = "/getSupervisorsList";

export const fetchAllianceList = "/fetchAllianceList";
export const fetchAllianceMemberDetails = "/fetchAllianceMemberDetails";
export const addNewMemberIntoAlliance = "/addNewMemberIntoAlliance";
export const updateLeader = "/updateLeader";
export const deleteMemberFromAlliance = "/deleteMemberFromAlliance";

export const fetchLeadersWithoutAcc = "/fetchLeadersWithoutAcc";
export const createNewLeaderAccount = "/createNewLeaderAccount";

export const createAlliance = "/createNewAlliance";
export const fetchOneTimeTasks = "/fetchOneTimeTasks";
export const fetchDailyTasks = "/fetchDailyTasks";
export const fetchWeeklyTasks = "/fetchWeeklyTasks";
export const fetchMonthlyTasks = "/fetchMonthlyTasks";
export const districtsPickedCount = "/districtsPickedCount";

export const login = "/login";
export const signup = "/signup";

export const oneTimeTaskApi = "/addOneTimeTask";
export const dailyTaskApi = "/addDailyTask";
export const weeklyTaskApi = "/addWeeklyTask";
export const monthlyTaskApi = "/addMonthlyTask";

export const fetchWeeklyTaskDetails = "/fetchWeeklyTaskDetails";
export const fetchOneTimeTaskDetails = "/fetchOneTimeTaskDetails";
export const fetchDailyTaskDetails = "/fetchDailyTaskDetails";

export const createMemberAccount = "/createMemberAccount";

export const memberLogin = "/memberLogin";
export const deleteMemberAccount = "/deleteMemberAccount";

export const fetchDailyTasksList = '/fetchDailyTasksList'
export const addDailyTasks='/addDailyTasks'
// export const login = "/login";
