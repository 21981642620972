import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { headingBlue, red } from "../assets/colors";
import { buttonStyle } from "./formStyle";
import HttpService from "../backend/requestApis";

const ConfirmationWindow = ({
	windowStatus,
	updateWindowStatus,
	isAllTasksCompleted,
	isDailyTask,
	options,
	isNoTaskChecked,
	setIsFormSubmitted,
	setIsLoading,
	setApiError
}) => {
	async function handleSubmit() {
		try {
			setIsLoading(true);
			HttpService(options).then((response) => {
				if (response.data.status === 1) {
					setIsFormSubmitted(true);
					setIsLoading(false);
				} else {
					setApiError('Error in submitting. Please Contact Support')
					setIsLoading(false);
				}
			}).catch(err => {
				console.log("Error in submiting form.", err)
				setApiError('Error in submitting. Please Contact Support')
				setIsLoading(false);
			});
			// setIsFormSubmitted(true);
		} catch (err) {
			console.log("Error in submitting form.", err);
			setApiError('Error in submitting. Please Contact Support')
			setIsLoading(false);
		}
	}
	return (
		<Dialog
			maxWidth="xs"
			fullWidth
			open={windowStatus}
			onClose={() => updateWindowStatus()}
		>
			<DialogTitle
				sx={{ height: "1.8rem", padding: "0.4rem 1rem 0.4rem 1rem" }}
				style={{
					backgroundColor: headingBlue,
					color: "white",
					fontSize: "1.1rem",
					fontWeight: "bold",
					textAlign: "center",
				}}
			>
				Confirmation!
			</DialogTitle>
			<DialogContent
				style={{
					textAlign: "center",
				}}
			>
				<div>
					{isNoTaskChecked && (
						<div>
							<p style={{ color: red, fontWeight: "bold" }}>
								You have not completed the tasks!
							</p>
							<p style={{ color: headingBlue, fontSize: "0.9rem" }}>
								Please complete at least one task to proceed to submit.
							</p>
						</div>
					)}
					{!isAllTasksCompleted && !isNoTaskChecked && (
						<div>
							<p style={{ color: red, fontWeight: "bold" }}>
								You have not completed all the tasks!
							</p>
							<p style={{ color: headingBlue, fontSize: "0.9rem" }}>
								Your task {isDailyTask && "for the day"} will be recorded as a
								partial submission.
							</p>
						</div>
					)}
					{isAllTasksCompleted && !isNoTaskChecked && (
						<div>
							<p style={{ color: red, fontWeight: "bold" }}>
								Alhamdulillah! You have completed all the tasks{" "}
								{isDailyTask && "for the day"}.
							</p>
						</div>
					)}

					{!isNoTaskChecked && (
						<p
							style={{ fontWeight: "bold", margin: "auto", color: headingBlue }}
						>
							Do you want to proceed?
						</p>
					)}
					<div style={{ textAlign: "center" }}>
						<Button
							style={{
								...buttonStyle,
								fontSize: "0.7rem",
								marginBottom: "0rem",
							}}
							onClick={handleSubmit}
							disabled={isNoTaskChecked}
						>
							Submit
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default ConfirmationWindow;
