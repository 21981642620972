import { createContext, useContext, useEffect, useState } from "react";
import useOutsideClick from "../hooks/useOutsideClick";
import { createPortal } from "react-dom";

const InfoButtonContext = createContext();

function InfoButton({ children }) {
	const [openId, setOpenId] = useState(null);
	const [position, setPosition] = useState(null);

	function open(id) {
		setOpenId(id);
	}

	function close() {
		setOpenId(null);
	}

	return (
		<InfoButtonContext.Provider
			value={{ openId, open, close, position, setPosition }}
		>
			{children}
		</InfoButtonContext.Provider>
	);
}

function Toggle({ id }) {
	const { openId, open, close, setPosition } = useContext(InfoButtonContext);

	function handleClick(e) {
		e.stopPropagation();
		const isMobile = window.innerWidth <= 768;
		const rect = e.target.closest("div").getBoundingClientRect();

		const desktopPosition = {
			x: window.innerWidth + rect.width - rect.x + 350,
			// x: window.innerWidth - rect.width - rect.x,
			// y: rect.y + rect.height + 8,
			y: rect.y - 15,
		};
		const mobilePosition = {
			x: window.innerWidth - 72 - 250,
			y: rect.y - 60,
		};
		let devicePosition = isMobile ? mobilePosition : desktopPosition;
		setPosition(devicePosition);

		if (openId !== id) {
			open(id);
		} else {
			close();
		}
	}

	return (
		<div onClick={handleClick}>
			<img
				src={require("../assets/images/info-button.png")}
				alt="info-button"
				style={{ width: "1.5rem", height: "1.5rem", cursor: "pointer" }}
			/>
		</div>
	);
}
function Window({ id, children }) {
	const { openId, position, close } = useContext(InfoButtonContext);

	const ref = useOutsideClick(close, false);

	if (openId !== id) return null;

	return createPortal(
		<div
			style={{ position: "absolute", top: position.y, left: position.x }}
			ref={ref}
		>
			{children}
		</div>,
		document.body
	);
}

InfoButton.Toggle = Toggle;
InfoButton.Window = Window;

export default InfoButton;
