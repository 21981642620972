import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
	headingBlue,
	maroon,
	mediumGrey,
	red,
	textColor,
	yellowish,
} from "../assets/colors";
import {
	deleteMemberFromAlliance,
	makeOrRemoveLeader,
	updateLeader,
} from "../backend/backendApis";
import HttpService from "../backend/requestApis";
import { useNavigate } from "react-router-dom";
import CustomDropDown from "./CustomDropDown";

const EditAllianceConfirmationDialog = (props) => {
	const navigate = useNavigate();
	const [confirmError, setConfirmError] = React.useState("");
	const [newSelectedLeader, setNewSelectedLeader] = useState("Select a new Leader");
	const [isDeletingLeader, setIsDeletingLeader] = useState(false);

	useEffect(() => {
		setIsDeletingLeader(props.isDeleteOperation && props.leaderIsLeader);
	}, [props.isDeleteOperation, props.leaderIsLeader]);

	function updateNewLeader(leader) {
		setNewSelectedLeader(leader);
	}

	const handleConfirm = () => {
		try {
			let payload = {
				contactNumber: props.contactNumber,
			};

			if (!props.isDeleteOperation) {
				payload.leaderContact = props.leaderContact;
			}
			if (props.isDeleteOperation && props.leaderIsLeader) {
				const newSelectedLeaderContact = props.allianceMembersDetails.find(
					(member) => member.name === newSelectedLeader
				).contactNumber;
				payload.leaderContact = newSelectedLeaderContact;
			}

			if (props.isDeleteOperation) {
				payload._id = props.selectedAllianceId;
			}

			let options = {
				url: props.isDeleteOperation ? deleteMemberFromAlliance : updateLeader,
				method: props.isDeleteOperation ? "DELETE" : "POST",
				// payload: {
				// 	contactNumber: props.contactNumber,
				// 	leaderContact: props.leaderContact,
				// },
				payload: payload,
				headers: {
					headers: {
						Authorization: localStorage.getItem("token")
							? `Bearer ${localStorage.getItem("token")}`
							: "",
					},
				},
			};
			HttpService(options)
				.then((res) => {
					props.updateLeaderDialogStatus(false, "", "", false, false);
					props.setRefreshAllianceMembersDetails(true);
				})
				.catch((err) => {
					console.log("Error in handling confirm: ", err);
					if (err?.status === 403) {
						localStorage.clear();
						navigate("/");
					} else {
						setConfirmError("Error in operation. Please contact support");
					}
				});
		} catch (err) {
			console.log("Error in handling confirm: ", err);
			setConfirmError("Error in operation. Please contact support");
		}
	};

	var titleStyle = {
		backgroundColor: headingBlue,
		color: "white",
		fontSize: "1rem",
		textAlign: "center",
	};

	var errorStyleText = {
		color: red,
		marginTop: 1,
		fontSize: 11,
		marginLeft: 5,
		display: "flex",
		justifyContent: "center",
	};

	return (
		<>
			<Dialog
				maxWidth="xs"
				fullWidth
				open={props.leaderDialogStatus}
				onClose={() => props.updateLeaderDialogStatus()}
			>
				<DialogTitle
					sx={{ height: "1.8rem", padding: "0.4rem" }}
					style={titleStyle}
				>
					Confirmation
				</DialogTitle>
				<DialogContent style={{ marginTop: "1rem", textAlign: "center" }}>
					<Typography style={{ padding: "0.3rem", color: maroon }}>
						{props.confirmationMsg}
					</Typography>
					<Typography style={errorStyleText}>{confirmError}</Typography>

					{isDeletingLeader && (
						<Box style={{padding: "0.7rem 0.2rem 0.2rem 0.2rem"}}>
						<CustomDropDown
							width="16rem"
							regular={true}
							value={newSelectedLeader}
							list={props.allianceMembersDetails}
							setFunc={updateNewLeader}
							placeHolder="Select a new Leader"
							leaderContact={props.leaderContact}
							isDeletingLeader="true"
						/>
						</Box>
					)}

					<Box style={{ textAlign: "center", marginTop: "1rem" }}>
						<Button
							style={{
								padding: "0.1rem 0.4rem 0.1rem 0.4rem",
								backgroundColor:
									isDeletingLeader && newSelectedLeader === "Select a new Leader"
										? mediumGrey
										: headingBlue,
								color: "white",
							}}
							disabled={
								(isDeletingLeader && newSelectedLeader === "Select a new Leader") ||
								false
							}
							onClick={() =>
								props.isNoActionDialog
									? props.updateLeaderDialogStatus(
											false,
											"",
											null,
											null,
											false,
											false
									  )
									: handleConfirm()
							}
						>
							{props.isNoActionDialog ? "OK" : "Confirm"}
						</Button>
						<Button
							style={{
								padding: "0.1rem",
								backgroundColor: "red",
								color: "white",
								marginLeft: "0.5rem",
								border: "1px solid",
							}}
							onClick={() => {
								// setNewSessionError(null)
								// setNewSession(null)
								props.updateLeaderDialogStatus(false, "", "", false, false);
								setNewSelectedLeader("Select a new Leader");
								setConfirmError("");
							}}
						>
							Close
						</Button>
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default EditAllianceConfirmationDialog;
