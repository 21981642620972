import React from "react";
import { headingBlue } from "../assets/colors";
import { imgContainer, imgStyle } from "./formStyle";

const ReportFormSubmitMessage = () => {
	return (
		<div style={imgContainer}>
			<img
				src={require("../assets/images/right-icon.png")}
				alt="right-icon"
				style={{ ...imgStyle, width: "7rem", height: "7rem" }}
			></img>
			<p style={{ color: headingBlue, fontWeight: "bold", fontSize: "1rem" }}>
				Alhamdulillah, your response has been recorded successfully!
			</p>
		</div>
	);
};

export default ReportFormSubmitMessage;
