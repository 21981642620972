import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'
import { headingBlue, red } from '../assets/colors'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { fetchAssignmentTableData, fetchDailyTasks, fetchOneTimeTasks } from '../backend/backendApis';
import HttpService from '../backend/requestApis';
import { useNavigate } from 'react-router-dom';
import { dashboardColumnMapping, reportColumnMapping } from '../utils/constants';
import dayjs from 'dayjs';
import CustomDatePicker from './CustomDatePicker';

const DownloadDialogBox = (props) => {

    const [isFilterDownload, setIsFilterDownload] = React.useState(true)
    const [isAllDownload, setIsAllDownload] = React.useState(false)
    const [downloadError, setDownloadError] = React.useState('')
    const [isCustomDownload, setIsCustomDownload] = React.useState(false)
    const [customStartDate, setCustomStartDate] = React.useState(null)
    const [customEndDate, setCustomEndDate] = React.useState(null)
    const [customStartDateError, setCustomStartDateError] = React.useState('')
    const [customEndDateError, setCustomEndDateError] = React.useState('')

    const navigate = useNavigate()

    const updateIsFilterDownload = () => {
        setIsFilterDownload(!isFilterDownload)
    }

    const updateIsAllDownload = () => {
        setIsAllDownload(!isAllDownload)
    }

    const updateCustomDownload = () => {
        setIsCustomDownload(!isCustomDownload)
    }

    const updateCustomStartDate = (startDate) => {
        setCustomStartDate(startDate)
    }

    const updateCustomEndDate = (endDate) => {
        setCustomEndDate(endDate)
    }

    const dashboardAllData = () => {
        return new Promise(async (resolve, reject) => {
            let options = {
                url: fetchAssignmentTableData,
                method: 'GET',
                params: {},
                headers: {
                    Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null
                  }
            }
            await HttpService(options).then(data => {
                resolve(data?.data?.data?.records)
                // dataArray.push(data?.data?.data?.records)
            }).catch(err => {
                reject(err)
            })

        })
    }

    const reportAllData = () => {
        return new Promise(async (resolve, reject) => {
            let url = fetchDailyTasks
            let params ={}
            switch(props.dashBoard){
                case 'Daily Task':
                    url = fetchDailyTasks
                    params = {
                     reportDate: dayjs(dayjs(dayjs(props.selectedDate).format()).utc()).tz('Asia/Kolkata').format("YYYY-MM-DD")
                    }
                    break;
                case 'One Time Task':
                    url = fetchOneTimeTasks
                    break;
            }
            let options = {
                url: url,
                method: 'GET',
                params: params,
                headers: {
                    Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null
                  }
            }
            await HttpService(options).then(data => {
                resolve(data?.data?.data?.data)
                // dataArray.push(data?.data?.data?.records)
            }).catch(err => {
                reject(err)
            })

        })
    }

    const reportCustomData = () => {
        return new Promise(async (resolve, reject) => {
            let url = fetchDailyTasks
            let params ={}
            switch(props.dashBoard){
                case 'Daily Task':
                    url = fetchDailyTasks
                    params = {
                     reportStartDate: dayjs(dayjs(dayjs(customStartDate).format()).utc()).tz('Asia/Kolkata').format("YYYY-MM-DD"),
                     reportEndDate: dayjs(dayjs(dayjs(customEndDate).format()).utc()).tz('Asia/Kolkata').format("YYYY-MM-DD")
                    }
                    break;
            }
            let options = {
                url: url,
                method: 'GET',
                params: params,
                headers: {
                    Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null
                  }
            }
            await HttpService(options).then(data => {
                resolve(data?.data?.data?.data)
                // dataArray.push(data?.data?.data?.records)
            }).catch(err => {
                reject(err)
            })

        })
    }

    const handleDownload = async () => {
        try{

            if( !isFilterDownload && !isAllDownload && !isCustomDownload){
                setDownloadError('One of the above option is required.')
            } 
            else {
                let isValid = true
                setDownloadError('')
                var dataArray = []

                if( isFilterDownload ){
                    dataArray.push(props.data)
                }
                
                if( isAllDownload){
                    if( props.isReport){
                        let allData = await reportAllData()
                        dataArray.push(allData)
                    } else {
                        let allData = await dashboardAllData()
                        dataArray.push(allData)
                    }
                }

                if( isCustomDownload){
                    if( props.isReport){
                        
                        if( !customStartDate){
                            isValid = false
                            setCustomStartDateError('Start Date is Required.')
                        } else {
                            setCustomStartDateError('')
                        }

                        if( !customEndDate){
                            isValid = false
                            setCustomEndDateError('End Date is Required.')
                        } else {
                            setCustomEndDateError('')
                        }

                        if( customStartDate >= customEndDate){
                            isValid = false
                            setDownloadError('Start Date should be less than End Date.')
                        } else {
                            setDownloadError('')
                        }
                        if( isValid){
                            let customData = await reportCustomData()
                            dataArray.push(customData)
                        }
                        
                    }
                }
                if( isValid){
                    console.log("dataArray: ", dataArray)
                    var columnMapping = {};
                    if( props.isReport ? columnMapping = reportColumnMapping : columnMapping = dashboardColumnMapping)
                    if (props.dashBoard === 'Daily Task'){
                        columnMapping['reportDate'] = 'Date'
                    }
                    // console.log("Data Arrayt: ",dataArray)
                    dataArray.forEach(data => {
                        const transformedData = data.map(row => {
                            const transformedRow = {}
                            Object.keys(row).forEach(key => {
                            if(columnMapping[key]){
                                transformedRow[columnMapping[key]] = row[key]
                            }
                            })
                            return transformedRow
                        })
                    
                        const workBook = XLSX.utils.book_new();
                        const workSheet = XLSX.utils.json_to_sheet(transformedData)
                    
                        XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet 1')
                    
                        const excelBuffer = XLSX.write(workBook, {bookType: "xlsx", type: 'array'})
                        const blob = new Blob([excelBuffer], {type: 'application/octet-stream'})
                        saveAs(blob, "WORKers District Assignment.xlsx")
                    })
                    props.updateDownloadDialogStatus(false)
                }
            }
            
        } catch(err){
            if(err?.status === 403){
                localStorage.clear()
                navigate('/')
              } else {
                console.log('Error in downloading excel: ', err)
              }
        }
      }


    var titleStyle={
        backgroundColor: headingBlue,
        color: 'white',
        fontSize:'1rem',
        display: "flex",
        alignItems: 'center'
    }

    var boxStyle={
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'left',
        flexDirection: 'column',
        padding: "1rem 0.75rem 0rem 0.75rem",
        color: headingBlue,
        width: '50%',
        // border: '1px solid'

    }
    var internalBoxStyle= {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        justifyContent: 'left',
        marginLeft: '0.5rem'
    }

    var checkBoxStyle= {
        padding: '0.1rem'
    }

    var typographyStyle = {
        marginLeft: '0.3rem',
        padding: "0.1rem"
    }

    var errorStyleText = {
        color: red,
        marginTop: 1,
        padding: '0.2rem 0rem 0rem 0.1rem',
        fontSize: 11,
        fontWeight: 550
        // marginLeft: 5
    }

    return (
        <>
            <Dialog maxWidth='xs' fullWidth open={props.dialogStatus} onClose={() => props.updateDownloadDialogStatus(false)}>
                <DialogTitle sx={{height: "1.8rem", padding: "0.4rem 0rem 0.4rem 1rem"  }}  style={titleStyle}>Download</DialogTitle>
                    <DialogContent style={{marginTop: '0.5rem', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                        <Box style={boxStyle}>
                            <Box style={internalBoxStyle}>
                                <Checkbox style={checkBoxStyle} checked={isFilterDownload}
                                    onClick={updateIsFilterDownload}
                                >

                                </Checkbox>
                                <Typography style={typographyStyle}>Selected Records</Typography>
                            </Box>
                            
                            <Box style={internalBoxStyle}>
                                <Checkbox style={checkBoxStyle} checked={isAllDownload}
                                    onClick={updateIsAllDownload}
                                >

                                </Checkbox>
                                <Typography style={typographyStyle}>All Records</Typography>
                            </Box>

                            {
                                props.dashBoard === 'Daily Task' && 
                                (
                                    <>
                                    <Box style={internalBoxStyle}>
                                        <Checkbox style={checkBoxStyle} checked={isCustomDownload}
                                            onClick={updateCustomDownload}
                                        >

                                        </Checkbox>
                                        <Typography style={typographyStyle}>Custom Download</Typography>
                                    </Box>
                                    </>
                                )
                            }
                        </Box>
                        { props.dashBoard === 'Daily Task' && isCustomDownload &&
                            <>
                                <Box style={{...internalBoxStyle, flexDirection: 'row'}}>
                                    <Box style={{...internalBoxStyle, flexDirection: 'column'}}>
                                        <CustomDatePicker selectedDate={customStartDate} setSelectedDate={updateCustomStartDate}></CustomDatePicker>
                                        <Typography style={errorStyleText}>{customStartDateError}</Typography>
                                    </Box>
                                    <Box style={{...internalBoxStyle, flexDirection: 'column'}}>
                                    <CustomDatePicker selectedDate={customEndDate} setSelectedDate={updateCustomEndDate}></CustomDatePicker>
                                        <Typography style={errorStyleText}>{customEndDateError}</Typography>
                                    </Box>
                                </Box>
                            </>
                        }
                        <Typography style={errorStyleText}>{downloadError}</Typography>
                        <Box style={{textAlign: 'center', marginTop: '0.7rem'}}>
                            <Button style={{padding: '0.1rem 0.4rem 0.1rem 0.4rem', backgroundColor: headingBlue, color: 'white'}}
                            onClick={handleDownload}
                            >Download</Button>
                                <Button style={{padding: '0.1rem', backgroundColor: 'red', color: 'white', marginLeft: "0.5rem", border: "1px solid"}} onClick={() => {
                                    // setNewSessionError(null)
                                    // setNewSession(null)
                                    props.updateDownloadDialogStatus(false)
                                }}>Close</Button>
                        </Box>
                        
                    </DialogContent>
                    
            </Dialog>
        </>
    )
}

export default DownloadDialogBox
