import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
// import dayjs from "dayjs";

const CustomDatePicker = ({ selectedDate, setSelectedDate, isNotRestricted = false }) => {
	return (
		<>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DatePicker
					label=""
					value={selectedDate}
					onChange={(date) => {
						setSelectedDate(date);
					}}
					format="DD/MM/YYYY"
					
					slotProps={{
						textField: {
							sx: {
								width: '9rem',
								padding: "0.1rem",
								"& .MuiInputBase-input": {
									padding: "0.3rem", // Adjust padding
									fontSize: "0.9rem", // Make font smaller
								},
							},
							size: "small",
						},
						popper: {
							modifiers: [
								{
									name: "flip",
									enabled: false, // Disable automatic flipping to keep the popover above
								},
								{
									name: "preventOverflow",
									options: {
										altAxis: true, // Allow it to open upwards
										tether: false, // Prevent moving due to space limits
									},
								},
							],
						},
					}}
					PopperProps={{
						placement: "top",
					}}
					minDate={dayjs(new Date("2024-11-01"))}
					maxDate= { isNotRestricted ? dayjs(new Date('2050-01-01')) :  dayjs(new Date())}
				></DatePicker>
			</LocalizationProvider>
			{/* <Typography style={errorStyleText}>{dateError}</Typography> */}
		</>
	);
};

export default CustomDatePicker;
