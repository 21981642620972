import { Box, Button, Checkbox, Container, Dialog, DialogContent, DialogTitle, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import CustomTextField from './CustomTextField'
import { useNavigate } from 'react-router-dom'
import { headingBlue, red } from '../assets/colors'
import { validateContact, validateEmail } from '../utils/validations'
import { addNewSupervisor, fetchWORKerDetails, getWORKerDetails } from '../backend/backendApis'
import HttpService from '../backend/requestApis'
import CustomRadioButton from './CustomRadioButtons.js'
import Loader from './Loader.js'

const AddLeaderDialogBox = (props) => {

    const navigate = useNavigate()

	var titleStyle={
    backgroundColor: headingBlue,
    color: 'white',
    fontSize:'1rem',
    display: "flex",
    alignItems: 'center'
  }

	var errorStyleText = {
    color: red,
    marginTop: 1,
    fontSize: 11,
    marginLeft: 5
	}

    var paperStyle = {
        marginTop: '1rem',
        padding: '0.2rem',
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center",
        textAlign: 'center',
        color: headingBlue,
        // flexDirction: 'row'
    }

    var gridStyle = {
        dispaly: 'flex',
        justifyContent: 'center',
        // textAlign: 'center'
        alignItems: 'center',
        padding : '0rem'
    }

	const [contact, setContact] = React.useState('')
	const [contactError, setContactError] = React.useState(null)
    const [WORKerDetails, setWORKerDetails] = React.useState(null)
    const [submitApiError, setSubmitApiError] = React.useState(null)
    const [supervisorEmail, setSupervisorEmail] = React.useState(null)
    const [supervisorEmailError, setSupervisorEmailError] = React.useState(null)
    const [isLoader, setIsLoader] = React.useState(null)

	const updateContact = (contact) => {
		setContact(contact)
	}

    const updateSupervisorEmail = (email) => {
        setSupervisorEmail(email)
    }

    const handleGetDetails = () => {
        try{
            let isValid = true
            let validateContactResult = validateContact(contact)
            

            if( validateContactResult != ''){
                setContactError(validateContactResult)
                isValid = false
            } else {
                setContactError('')
            }

            if(isValid){
                let options = {
                    url: getWORKerDetails,
                    method: 'GET',
                    params: {
                        "contactNumber": contact
                    },
                    headers: {
                        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null
                    }
                }
                HttpService(options).then(data => {
                    setContactError('')
                    setSubmitApiError('')
                    setSupervisorEmail(null)
                    setSupervisorEmailError('')
                    setWORKerDetails(data?.data?.data)
                }).catch(err => {
                    if(err?.status === 403){
                        localStorage.clear()
                        navigate('/')
                      } else {
                        console.log("Error in fetching WORKer details.", err)
                        setContactError(err?.response?.data?.description)
                      }
                })
            }

        } catch(err){
            console.log('Error in getting WORKer Details: ', err)
        }
    }

	const handleAdd = () => {
		try{
            setIsLoader(true)
			let isValid = true

            let validateEmailResult = validateEmail(supervisorEmail, 'Email')
            if(validateEmailResult != ''){
                setSupervisorEmailError(validateEmailResult)
                isValid= false
            } else {
                setSupervisorEmailError('')
            }
		// 	let validateSessionResult = validateSession(newSession)

		// 	if( validateSessionResult != ''){
		// 		setNewSessionError(validateSessionResult)
		// 		isValid = false
		// 	} else {
		// 		setNewSessionError(null)
		// 	}

			if( isValid){
				let options = {
					url: addNewSupervisor,
					method: 'POST',
					payload:{
						"contactNumber": contact,
                        'name': WORKerDetails['name'],
                        'gender': WORKerDetails['gender'],
                        'district': WORKerDetails['district'],
                        'chapter': WORKerDetails['chapter'],
                        'email': supervisorEmail
					},
					headers: {headers: {
						Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''
					}}
				}
				HttpService(options).then(() => {
                    setIsLoader(false)
					props.updateAddLeaderSupervisorDialogStatus(false)
				}).catch(err => {
                    setIsLoader(false)
					console.log("Error in adding new supervisor: ", err)
					if(err?.status === 403){
                        localStorage.clear()
                        navigate('/')
                      } else {
                        console.log("Error in fetching WORKer details.", err)
                        if( err?.status === 409){
                            setSubmitApiError(err?.response?.data?.description)
                        } else {
                            setSubmitApiError('There is some error. Please contact support.')

                        }
                      }
				})	
			} else {
                setIsLoader(false)
            }
		} catch(err){
			console.log("Error in adding new supervisor: ", err)
		} 
	}


  return (
    <>
        <Dialog maxWidth= 'xs' fullWidth open={props.addLeaderSupervisorDialogBoxStatus} onClose={() => props.updateAddLeaderSupervisorDialogStatus()}>
            <DialogTitle sx={{height: "1.8rem", padding: "0.4rem 0rem 0.4rem 1rem"  }}  style={titleStyle}> Add Supervisor </DialogTitle>
            <DialogContent style={{marginTop: '1rem'}}>
                <Box style={{textAlign: "center"}}>
                    <CustomTextField placeHolder= "Conatct Number" value={contact} setFunc={updateContact}></CustomTextField>
                    <Typography style={errorStyleText}>{contactError}</Typography>
                </Box>

                <Box style={{textAlign: 'center', marginTop: '1rem'}}>
                    <Button style={{padding: '0.1rem 0.4rem 0.1rem 0.4rem', border: `1.5px solid ${headingBlue}`, color: headingBlue, fontWeight: 500}}
                    onClick={handleGetDetails}
                    >Get Details</Button>
                    {
                        !WORKerDetails && (
                            <Button style={{padding: '0.1rem', backgroundColor: 'red', color: 'white', marginLeft: "0.5rem", border: "1px solid"}} onClick={() => {
                                // setNewSessionError(null)
                                // setNewSession(null)
                                props.updateAddLeaderSupervisorDialogStatus()
                            }}>Close</Button>
                        )
                    }
                </Box>

                {
                    WORKerDetails && (
                        <>
                        <Paper style={paperStyle}>

                            <Grid container spacing={1} style={gridStyle}>
                                <Grid item xs={3} sm={3} sx={gridStyle}>
                                    {WORKerDetails?.name ? WORKerDetails.name : '-'}
                                </Grid>
                                <Grid item xs={3} sm={3} style={gridStyle}>
                                    {WORKerDetails?.gender ? WORKerDetails?.gender : '-'}
                                </Grid>
                                <Grid item xs={3} sm={3} style={gridStyle}>
                                    {WORKerDetails?.district ? WORKerDetails?.district : '-'}
                                </Grid>
                                <Grid item xs={3} sm={3} style={gridStyle}>
                                    {WORKerDetails?.chapter ? WORKerDetails?.chapter : '-'}
                                </Grid>
                                {/* <Grid item xs={3} sm={3} style={{...gridStyle, flexDirection: 'row'}}> */}
                                    {/* <Box style={{display: 'flex', flexDirection: 'row', padding: '0.5rem'}}>
                                        <Typography>Leader</Typography>
                                        <Checkbox style={{padding: '0rem'}}></Checkbox>    
                                    </Box> */}
                                    {/* <Box style={{ padding: "0.1rem 0.1rem 0.1rem 0.5rem"}}>
                                        <CustomRadioButton label1='Leader' value1={false} value2={true} label2='Supervisor'></CustomRadioButton>
                                    </Box> */}
                                    

                                    
                                {/* </Grid> */}

                            </Grid>
                            <Box style={{display: "flex", justifyContent: 'center',flexDirection: "column", alignItems: 'center', padding: "0.4rem"}}>
                                <CustomTextField placeHolder="Enter supervisor's email" value={supervisorEmail} setFunc={updateSupervisorEmail}></CustomTextField>
                                <Typography style={{...errorStyleText, textAlign: 'center', fontWeight: 550}}>{supervisorEmailError}</Typography>
                            </Box>
                        </Paper>

                        <Typography style={{...errorStyleText, textAlign: 'center', paddingTop: '0.5rem', fontWeight: 550}}>{submitApiError}</Typography>

                        <Box style={{textAlign: 'center', marginTop: '0.5rem'}}>
                        <Button style={{padding: '0.1rem 0.4rem 0.1rem 0.4rem', backgroundColor: headingBlue, color: 'white'}}
                        onClick={handleAdd}
                        >ADD Supervisor</Button>
                            <Button style={{padding: '0.1rem', backgroundColor: 'red', color: 'white', marginLeft: "0.5rem", border: "1px solid"}} onClick={() => {
                                // setNewSessionError(null)
                                // setNewSession(null)
                                props.updateAddLeaderSupervisorDialogStatus()
                            }}>Close</Button>
                        </Box>
                        {isLoader && 
                            <>
                                <Loader></Loader>
                            </>
                        }
                        </>
                    )
                    
                }

                

                

            </DialogContent>
        </Dialog>   
    </>
  )
}

export default AddLeaderDialogBox